import React, { forwardRef } from "react";
import Link from "next/link";

interface CustomLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  href: string;
}

// Link for HeadlessUI and Next.js
// https://headlessui.com/react/menu#integrating-with-next-js
const CustomLink = forwardRef<HTMLAnchorElement, CustomLinkProps>(
  (props, ref) => {
    let { href, children, ...rest } = props;

    if (href === "") {
      return (
        <a ref={ref} {...rest}>
          {children}
        </a>
      );
    } else {
      return (
        <Link href={href}>
          <span ref={ref} {...rest}>
            {children}
          </span>
        </Link>
      );
    }
  }
);

CustomLink.displayName = "CustomLink";

export default CustomLink;
