// language select dropdown
import { useState, useEffect } from "react";
import { getLanguage, setLanguage } from "@src/utils/globals";
import Dropdown from "./Dropdown";

interface lngItem {
  name: string;
  value: string;
}

const lngs: lngItem[] = [
  { name: "中文", value: "zh" },
  { name: "English", value: "en" },
];

interface LangsDropdownProps {
  i18n: any;
}

export default function LangsDropdown({ i18n }: LangsDropdownProps) {
  const [curLng, setCurLng] = useState(lngs[0]);

  // change global language type
  function changeLng(lng: lngItem) {
    setCurLng(lng);
    setLanguage(lng.value);
    i18n.changeLanguage(lng.value);
  }

  // init language type
  useEffect(() => {
    const language = getLanguage();
    for (const lng of lngs) {
      if (lng.value === language) {
        // set it asynchronously in case of it would be sometime init failed
        setTimeout(() => changeLng(lng));
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Dropdown value={curLng} onChange={changeLng as any} options={lngs} />;
}
