// import { REGION } from "@src/constant";
import { DialogContainer, Button, Image } from "@components/common";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "@src/store";
import { openContactDialog } from "@src/store/common";

const questionnaireLink = "https://wj.qq.com/s2/14488320/rlq7/";
// const slackJoinLink =
//   "https://join.slack.com/t/colossalaiworkspace/shared_invite/zt-2404o93sy-Y3~br1qkIeEcMOVSfJ8YYg";
// const slackLink = "https://colossalaiworkspace.slack.com/team/U02NCSGFD52";

export default function ContactDialog() {
  const { t } = useTranslation();

  const open = useAppSelector((state) => state.common.isContactDialogOpen);
  const dispatch = useAppDispatch();

  return (
    <DialogContainer
      className="max-h-fit"
      open={open}
      setOpen={(isOpen) => dispatch(openContactDialog(isOpen))}
    >
      <div className="font-mono min-h-[300px] w-[730px] pb-6">
        <div className="mb-4 text-xl font-semibold">
          {t("home.footer.labels.contact")}
        </div>
        <div className="text-gray-500 mb-8">{t("common.contact.tip")}</div>
        <div className="flex items-end">
          <div className="inline-block w-1/2">
            <div className="font-semibold">{`${t(
              "common.contact.title1"
            )}:`}</div>
            {/* {REGION !== "cn" ? ( */}
            <Image
              className="block w-72 rounded mt-4 ml-2"
              src={`/refoundWechat.jpeg`}
              alt="Wechat refound"
            />
            {/* ) : (
              <>
                <div className="mt-2 mr-[70px]">
                  <div>
                    {t("common.contact.slack")}
                    <Button
                      type="link"
                      className="!text-base"
                      target="_blank"
                      href={slackJoinLink}
                    >
                      {t("common.contact.click")}
                    </Button>
                  </div>
                  <div className="mt-2">
                    {t("common.contact.haveJoined")}
                    <Button
                      type="link"
                      className="!text-base"
                      href={slackLink}
                      target="_blank"
                    >
                      {t("common.contact.click")}
                    </Button>
                  </div>
                </div>
              </>
            )} */}
          </div>

          <div className="inline-block w-1/2">
            <div className="inline-block font-semibold mr-2">{`${t(
              "common.contact.title2"
            )}:`}</div>

            <Button
              className="!text-base"
              type="link"
              target="_blank"
              href={questionnaireLink}
            >
              {t("common.contact.questionnaire")}
            </Button>
            <Image
              className="block w-72 rounded mt-4 ml-2"
              src={`/contactWechat.jpeg`}
              alt="Wechat contact"
            />
          </div>
        </div>
        <div className="font-semibold mt-8 mb-2">{`${t(
          "resource.other"
        )}:`}</div>

        <span className="mr-8">{`${t(
          "account.common.phone"
        )}: 010-81377587`}</span>
        <span>{`${t("account.common.email")}: service@luchentech.com`}</span>
      </div>
    </DialogContainer>
  );
}
