import DialogContainer from "../DialogContainer";
import { useTranslation } from "react-i18next";
import FileViewer from "./FileViewer";
import Empty from "../Empty";

interface FileViewDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  filename?: string;
  fileContent: string;
}

export const extensionToLanguage = {
  js: "javascript",
  ts: "typescript",
  jsx: "javascript",
  tsx: "typescript",
  py: "python",
  java: "java",
  rb: "ruby",
  cs: "csharp",
  c: "c",
  cpp: "cpp",
  go: "go",
  php: "php",
  sql: "sql",
  swift: "swift",
  kotlin: "kotlin",
  dart: "dart",
  rs: "rust",
  sh: "bash",
  md: "markdown",
  html: "html",
  css: "css",
  json: "json",
  yaml: "yaml",
  toml: "toml",
  xml: "xml",
  yml: "yaml",
  txt: "plaintext",
  list: "plaintext",
} as Record<string, string>;

export const allowedFileType = Object.keys(extensionToLanguage);

export default function FileViewDialog({
  open,
  setOpen,
  filename,
  fileContent = "",
}: FileViewDialogProps) {
  const { t } = useTranslation();

  const getLanguageFromFileName = (filename: string | undefined) => {
    if (!filename) {
      return "plaintext";
    }

    const extension = filename.split(".").pop();

    // if the extension is not in the allowed list, return plaintext
    // otherwise return the language
    if (!allowedFileType.includes(extension!) || !extension) {
      return "plaintext";
    } else {
      return extensionToLanguage[extension!];
    }
  };

  return (
    <DialogContainer open={open} setOpen={setOpen} className="w-full h-[80vh]">
      <label className="block font-medium text-gray-700">
        {t("common.action.preview")}
      </label>
      {fileContent ? (
        <div className="mt-2 y-2 border-2 border-gray-300 rounded whitespace-pre-wrap">
          <FileViewer
            language={getLanguageFromFileName(filename)}
            fileContent={fileContent}
          />
        </div>
      ) : (
        <Empty className="mt-[20vh]" />
      )}
    </DialogContainer>
  );
}
