import { request } from "@utils/http";
import { globalFetcher, SERVER } from "../globals";
import { PagerRequest, PagerResponse } from "./interface";

export interface GPU {
  gpuType: string;
  availableCounts: number[];
  manufacturer: string;
}

// get available GPU types
export interface GetGpuTypesResponse {
  gpu: GPU[];
}

export interface Price {
  perDay: number;
  tidePerHour: number;
  perHour: number;
  perMonth: number;
  perWeek: number;
  perYear: number;
}

export const getGpuTypeInfo = async (params: {
  poolType: "public" | "private";
  jobType: "job" | "inference" | "notebook";
}) => request(globalFetcher, `${SERVER}/api/resource/user/available`, params);

interface Resource {
  cpu: number;
  memory: number;
  gpu: {
    resource: string;
    quota: number;
    memory: number;
  };
}

export interface PublicNode {
  totalResource: Resource;
  usageResource: Resource;
  nodeName: string;
  price: Price;
}

export const getPublicList = async (params: {
  pager: PagerRequest;
}): Promise<{ nodeInfos: PublicNode[]; pager: PagerResponse }> =>
  request(globalFetcher, `${SERVER}/api/resource/user/public/list`, params);

export type prepayType = "perDay" | "perWeek" | "perMonth";

export type dateType = "perHour" | "tidePerHour" | prepayType;

export interface PrivateNode {
  totalResource: Resource;
  usageResource: Resource;
  nodeName: string;
  expireDate: number;
  price: {
    payType: dateType;
    payPrice: number;
  };
}

export const getPrivateList = async (params: {
  pager: PagerRequest;
}): Promise<{ nodeInfos: PrivateNode[]; pager: PagerResponse }> =>
  request(globalFetcher, `${SERVER}/api/resource/user/private/list`, params);

export interface Machine {
  // all size unit is KB
  status: "running" | "disableCreate" | "maintenance";
  warningLabel: string;
  warningContent: string;
  cpuName: string;
  cudaVersion: string;
  dataDiskSize: number;
  systemFreeDiskSizeGB: number; // GB
  dataFreeDiskSizeGB: number; // GB
  diskType: string;
  expandableDiskSize: number;
  expireDate: number;
  gpu: {
    availableGPU: number;
    gpuMemory: number;
    gpuName: string;
    totalGPU: number;
  };
  gpuDriver: string;
  machineId: string;
  machineName: string;
  memoryPerGpu: number;
  numberOfCPUPerGpu: number;
  price: Price;
  systemDiskSize: number;
  multiNodeInterconnect?: string;
  architecture: "x86" | "arm";
  supportDocker: boolean;
  supportCommonData: boolean;
}

export const getMachineList = async (params: {
  gpuTypes: string[];
  region: Region["value"];
  pager: PagerRequest;
  requestedGpuNum: number;
}): Promise<{ machineInfos: Machine[]; pager: PagerResponse }> =>
  request(globalFetcher, `${SERVER}/api/resource/user/machine/list`, params);

export const getMachineInfo = async (params: {
  machineName: Machine["machineName"];
  region: Region["value"];
}): Promise<{ machineInfo: Machine }> =>
  request(globalFetcher, `${SERVER}/api/resource/user/machine/info`, params);

export interface MachineType {
  gpuName: string;
  totalGPU: number;
  availableGPU: number;
}

export const getMachineTypes = async (params: {
  region: string;
}): Promise<{
  machines: MachineType[];
}> =>
  request(globalFetcher, `${SERVER}/api/resource/user/machine_type`, params);

export interface DataDisk {
  pricePerGBPerDay: number;
  pricePerGBPerHour: number;
  defaultDataDiskSizeGB: number;
  extraDataDiskSizeLimitGB: number;
  sysDiskSizeGB: number;
  systemFreeDiskSizeGB: number;
  dataFreeDiskSizeGB: number;
}

export const getDataDiskInfo = async (params: {
  hostNodeName: string;
  region: Region["value"];
}): Promise<DataDisk> =>
  request(globalFetcher, `${SERVER}/api/resource/data_disk/info`, params);

export interface DataDiskChangeParams {
  id: string;
  sizeChangedToGB: number;
  parentNodeName: string;
  region: string;
}

export const changeDataDisk = async (params: DataDiskChangeParams) =>
  request(globalFetcher, `${SERVER}/api/notebook/data_disk/change`, params);

export interface Region {
  domain: string;
  name: string;
  value: string;
  supportFileStorage: boolean;
}

export const getRegionList = async (): Promise<{
  regions: Region[];
}> => request(globalFetcher, `${SERVER}/api/resource/region/list`);

export const lockMachines = async (params: {
  machineType: string;
  machineNum: number;
}): Promise<{ availableNodeNames: string[]; allAvailable: boolean }> =>
  request(globalFetcher, `${SERVER}/api/resource/user/cordonNodes`, params);

export const subscribeMachines = async (params: {
  nodeNames: string[];
  expiredDate: number;
  userId: string;
  payStatus: "paySuccess" | "payFailed";
  machineType: string;
  expire: {
    num: number;
    dateType: dateType;
  };
}): Promise<{ nodes: { nodeName: string }[] }> =>
  request(globalFetcher, `${SERVER}/api/resource/user/subscribe`, params);

interface MachinePrice extends Price {
  perHourOrigin: number;
}

export interface machinePrice {
  gpuType: { gpuName: string; gpuMemory: number };
  price: MachinePrice;
}

export const getPrices = async (): Promise<{
  machinePrices: machinePrice[];
}> => request(globalFetcher, `${SERVER}/api/resource/machine/prices`);

export interface TideFactor {
  gpuType: string;
  factorList: {
    factor: number;
    gpuUsageRate: number;
  }[];
}

export const getTideFactors = async (): Promise<{
  factors: TideFactor[];
}> => request(globalFetcher, `${SERVER}/api/tidefactors/list`);
