import React from "react";
import { HiOutlineFolderPlus } from "react-icons/hi2";

interface EmptyStateProps {
  text: string;
  Icon?(props: React.ComponentProps<"svg">): JSX.Element;
  onClick?(e: React.MouseEvent<HTMLButtonElement>): any;
}

export default function EmptyState({ text, Icon, onClick }: EmptyStateProps) {
  return (
    <button
      type="button"
      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      onClick={onClick}
    >
      {Icon ? (
        <Icon className="mx-auto h-12 w-12 text-gray-400" />
      ) : (
        <HiOutlineFolderPlus className="mx-auto h-12 w-12 text-gray-400" />
      )}
      <span className="mt-2 block text-sm font-medium text-gray-900">
        {text}
      </span>
    </button>
  );
}
