interface ProgressBarProps {
  progress: number;
}

export default function ProgressBar({ progress }: ProgressBarProps) {
  const renderProgress = Math.min(progress, 100);

  return (
    <div className="flex items-center">
      <div className="w-full h-3 bg-gray-200 rounded-md  dark:bg-gray-700">
        <div
          className="text-xs font-medium h-full text-white text-center p-0.5 leading-none rounded-md min-w-[1rem] bg-blue-500"
          style={{
            width: `${renderProgress}%`,
          }}
        />
      </div>
      <div className="w-16 text-right text-sm font-medium">
        {renderProgress.toFixed(1)}%
      </div>
    </div>
  );
}
