import { AlertDialog } from "@components/common";
import { useTranslation, Trans } from "react-i18next";

interface DeleteAlertProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  type: string;
  name: string;
  handleI18nKey?: string;
  onConfirm: () => void;
}

export default function DeleteAlert({
  open,
  setOpen,
  type,
  name = "",
  handleI18nKey = "common.action.delete",
  onConfirm,
}: DeleteAlertProps) {
  const { t } = useTranslation();

  const description = (
    <Trans
      i18nKey="app.deleteAlert.description"
      values={{
        handle: t(handleI18nKey).toLowerCase(),
        name,
      }}
      components={[<span key="1" className="font-medium text-gray-900"></span>]}
    />
  );

  return (
    <AlertDialog
      open={open}
      setOpen={setOpen}
      title={t(`${type}.deleteAlert.title`)}
      description={description}
      buttonText={t(handleI18nKey)}
      onConfirm={onConfirm}
    />
  );
}
