import { classNames } from "@src/utils";
import { HiXCircle } from "react-icons/hi2";

interface ErrorAlertProps {
  title?: string;
  children?: React.ReactNode;
}

export default function ErrorAlert({ title, children }: ErrorAlertProps) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <HiXCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          {title && (
            <h3 className="text-sm font-medium text-red-800">{title}</h3>
          )}
          {children && (
            <div
              className={classNames("text-sm text-red-700", title && "mt-2")}
            >
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
