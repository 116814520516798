import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Avatar } from "@components/common";
import utils from "@src/utils";
import CustomLink from "./CustomLink";
import { NAVBAR_LINKS } from "./constant";
import { useTranslation } from "react-i18next";
import { HiChevronDown } from "react-icons/hi2";
import { useAppSelector } from "@src/store";

interface AvatarDropdownProps {
  username: string;
  avatarSrc?: string;
  onSignout?(): any;
}

export default function AvatarDropdown({
  username,
  avatarSrc,
  onSignout,
}: AvatarDropdownProps) {
  const { t } = useTranslation();

  const userInfo = useAppSelector((state) => state.common.userInfo);

  const navLinks = userInfo?.group?.includes("admin")
    ? NAVBAR_LINKS
    : NAVBAR_LINKS.slice(0, 2);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center items-center bg-white px-4 py-2 text-gray-700">
          <Avatar src={avatarSrc} className="w-8 h-8" />
          <HiChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-[60] mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-4 py-3 text-sm">
            <div className="truncate font-semibold  text-blue-600 text-lg leading-5">
              {username}
            </div>
            {/* <div className="text-xs  pt-2">{`${t("account.common.userId")}: ${
              userInfo?.userId || ""
            }`}</div> */}
            {userInfo?.mainUserId && (
              <div className="text-xs  pt-2">{`${t(
                "account.common.mainUserId"
              )}: ${userInfo?.mainUserId || ""}`}</div>
            )}
            {!!userInfo?.expiredTime && (
              <p className="flex text-xs pt-2">
                {`${t("account.common.expiration")}: ${userInfo.expiredTime}`}
              </p>
            )}
          </div>
          <div className="py-1">
            {navLinks.map((item) => (
              <Menu.Item key={item.id}>
                {({ active }) => (
                  <CustomLink
                    href={item.href}
                    className={utils.classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      window.location.pathname.includes(item.id)
                        ? "font-semibold bg-blue-50"
                        : "",
                      "block px-4 py-2 text-sm transition-colors"
                    )}
                  >
                    {t(item.text)}
                  </CustomLink>
                )}
              </Menu.Item>
            ))}
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <CustomLink
                  href=""
                  className={utils.classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm cursor-pointer"
                  )}
                  onClick={onSignout}
                >
                  {t("component.common.avatarDropdown.signOut")}
                </CustomLink>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
