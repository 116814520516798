export default function SidebarLayout(props: any) {
  return (
    <div className="h-full flex overflow-hidden min-h-[875px]">
      <div className="flex flex-col flex-grow-0 flex-shrink-0 w-fit">
        {props.sidebar}
      </div>
      <div className="flex-auto w-0">{props.children}</div>
    </div>
  );
}
