import React from "react";

export interface SidePanelItemProps {
  Icon: React.ElementType;
  title: string;
  items: any[];
}

export interface SidePanelProps {
  groups?: SidePanelItemProps[];
}

export default function SidePanel({ groups }: SidePanelProps) {
  return (
    <div className="flex flex-col pl-4 py-2 border-l min-h-full">
      {groups?.map((group, index) => {
        return (
          <div key={index} className="flex flex-col">
            <div className="flex flex-row items-center">
              <div className="font-bold text-gray-700">{group.title}</div>
            </div>

            {/* list of items passed by user */}
            <div className="flex flex-col mt-2">
              {group.items.map((item, index) => {
                return <div key={index}>{item}</div>;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
