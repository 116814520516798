import DialogContainer from "./DialogContainer";
import { useTranslation } from "react-i18next";
import Table from "./Table";

export interface jobHyperParameter extends Record<string, any> {
  name: string;
  value: any;
}

interface JobParamsDialogProps {
  open: boolean;
  setOpen(value: boolean): any;
  hyperParameters: jobHyperParameter[];
}

const columns = [
  { title: "Name", dataIndex: "name" },
  { title: "Value", dataIndex: "value" },
];

export default function JobParamsDialog({
  open,
  setOpen,
  hyperParameters,
}: JobParamsDialogProps) {
  const { t } = useTranslation();

  return (
    <DialogContainer
      className="max-h-[80vh] min-w-[50%]"
      open={open}
      setOpen={setOpen}
    >
      <label className="block font-medium text-gray-700 text-base">
        {t("component.common.env.header")}
      </label>
      <div className="rounded-sm bg-gray-200 my-2 p-2 overflow-auto">
        {hyperParameters ? (
          <Table columns={columns} dataSource={hyperParameters} rowKey="name" />
        ) : (
          "This job does not contain HyperParameters.json"
        )}
      </div>
    </DialogContainer>
  );
}
