export default function AccountLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    // dummy
    <div className="min-h-screen flex min-w-full items-center flex-col justify-center pt-32 pb-8 sm:px-6 lg:px-8 bg-gray-50">
      <div className="bg-white h-full w-[912px] shadow py-4 rounded flex items-center">
        {children}
      </div>
    </div>
  );
}
