import { HiOutlineBell } from "react-icons/hi2";
import {
  getUserNotifications,
  markUserNotificationsAsRead,
  UserNotification,
} from "@utils/api/account";
import { useEffect, useState, Fragment } from "react";
import { format } from "date-fns";
import { useAppDispatch } from "@src/store";
import { sendErrorNotification } from "@src/utils";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

export default function UserNotificationDropdown() {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useAppDispatch();

  // fetch init notifications
  useEffect(() => {
    getLatestNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLatestNotifications = async () => {
    try {
      const { notifications } = await getUserNotifications({});
      setNotifications(notifications);
    } catch (error) {
      sendErrorNotification(error, dispatch);
    }
  };

  const toggleNotification = () => {
    setDropdownOpen(!dropdownOpen);
    if (!dropdownOpen) {
      markAllAsRead();
    }
  };

  const markAllAsRead = async () => {
    const unreadNotificationIds: string[] = [];
    for (const n of notifications) {
      if (n.status === "created") {
        n.status = "read";
      }
      unreadNotificationIds.push(n.id);
    }

    try {
      await markUserNotificationsAsRead({ ids: unreadNotificationIds });
    } catch (error) {
      sendErrorNotification(error, dispatch);
    }
  };

  const addMoreNotifications = async () => {
    try {
      const { notifications: newNotifications } = await getUserNotifications({
        pager: {
          currentPage: Math.floor(notifications.length / 10 + 1),
          pageSize: 10,
        },
      });

      setNotifications([
        ...notifications,
        ...newNotifications.slice(notifications.length % 10, 10),
      ]);
    } catch (error) {
      sendErrorNotification(error, dispatch);
    }
  };

  const hasUnreadNotifications = notifications.some(
    (n) => n.status === "created"
  );

  return (
    <div className="relative inline-block text-left h-8">
      <button
        className="relative flex-shrink-0 rounded-full bg-white p-1 text-gray-500 hover:text-gray-900 cursor-pointer"
        onClick={toggleNotification}
      >
        <HiOutlineBell className="h-6 w-6" aria-hidden="true" />
        {hasUnreadNotifications && (
          <span className="absolute top-0 right-0 inline-block w-2 h-2 bg-red-500 rounded-full"></span>
        )}
      </button>

      <Transition
        show={dropdownOpen}
        as={Fragment}
        enter="transition ease-in duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute right-0 z-[60] mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-[80vh] overflow-y-auto overflow-x-hidden">
          {notifications.map((n) => (
            <div key={n.id}>
              <div>
                <div className="text-gray-700 block px-4 py-2 text-sm transition-colors">
                  {n.content}
                </div>
                <div className="text-gray-400 block px-4 pb-2 text-xs transition-colors">
                  {format(n.timeStampUnixMs, "yyyy-MM-dd HH:mm:ss")}
                </div>
              </div>
            </div>
          ))}
          <button
            className={
              "bg-blue-500 block px-4 py-2 text-sm text-white transition-colors w-full"
            }
            onClick={addMoreNotifications}
          >
            {t("component.common.navBar.loadMoreNotification")}
          </button>
        </div>
      </Transition>
      <div
        className={`fixed inset-0 z-[50] ${dropdownOpen ? "block" : "hidden"}`}
        onClick={() => setDropdownOpen(false)}
      ></div>
    </div>
  );
}
