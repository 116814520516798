import { useTranslation } from "react-i18next";
import { Image } from "@components/common";
import React from "react";

interface EmptyProps {
  className?: string;
  text?: string | React.ReactNode;
  [key: string]: any;
}

export default function EmptyState({
  className,
  text,
  ...otherProps
}: EmptyProps) {
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col items-center justify-center ${className}`}
      {...otherProps}
    >
      <Image className="m-0 w-24" src={`/noData.svg`} alt="No data" />
      <div className="mt-4 text-gray-500 whitespace-nowrap">
        {text ? text : t("component.common.empty.noData")}
      </div>
    </div>
  );
}
