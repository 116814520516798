interface GridLayoutProps {
  children: JSX.Element[] | JSX.Element;
}

export default function GridLayout({ children }: GridLayoutProps) {
  return (
    <div className="w-full overflow-auto flex justify-center">
      <div className="grid gap-x-6 gap-y-6 grid-cols-2 w-full flex-grow ">
        {children}
      </div>
    </div>
  );
}
