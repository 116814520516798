import { Layout, ConsoleSidebar } from "@components/common";
import AppLayout from "@components/common/Layout/AppLayout";
import { useTranslation } from "react-i18next";
import { initRegions, initTideFactors } from "@src/store/common";
import { useAppSelector, useAppDispatch } from "@src/store";
import { getRegionList, getTideFactors } from "@utils/api/resource";
import { sendErrorNotification } from "@src/utils";
import { useEffect, useRef } from "react";
import { DEFAULT_CONSOLE_APP } from "@src/constant";
import { useRouter } from "next/router";

export const consolePathExp = /^\/console\/([^/]+)/;

export default function ConsoleLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();

  const router = useRouter();
  const { current: routes } = useRef<Record<string, any>>({
    resource: [
      {
        path: "/console/resource",
        breadcrumb: t("resource.title"),
      },
      {
        path: "/console/resource/create",
        breadcrumb: t("resource.create"),
      },
    ],
    development: [
      {
        path: "/console/development",
        breadcrumb: t("development.header.name"),
      },
      {
        path: "/console/development/create",
        breadcrumb: t("common.action.create"),
      },
      {
        path: "/console/development/edit",
        breadcrumb: t("development.action.update"),
      },
    ],
    image: [
      { path: "/console/image", breadcrumb: t("image.header.name") },
      {
        path: "/console/image/detail",
        breadcrumb: t("common.header.detail"),
      },
      // // image build tasks
      // { path: "/console/image/build", breadcrumb: t("common.action.build") },
      {
        path: "/console/image/task",
        breadcrumb: t("image.header.imageBuildTasks"),
      },
      // {
      //   path: "/console/image/task/detail/:id",
      //   breadcrumb: t("common.header.detail"),
      // },
    ],
    filestorage: [
      {
        path: "/console/filestorage",
        breadcrumb: t("filestorage.header.name"),
      },
    ],
    dataset: [
      { path: "/console/dataset", breadcrumb: t("publicdata.header.name") },
    ],
    user: [
      { path: "/console/user", breadcrumb: t("user.header.name") },
      { path: "/console/user/sshkey", breadcrumb: t("user.header.sshKey") },
    ],
  });

  const appType = router.pathname.match(consolePathExp)?.[1];

  const dispatch = useAppDispatch();

  const { regions: regionsList, tideFactors } = useAppSelector(
    (state) => state.common
  );

  const getRegions = async () => {
    try {
      if (regionsList.length) return;
      const { regions } = await getRegionList();
      dispatch(initRegions(regions));
    } catch (err) {
      sendErrorNotification(err, dispatch);
    }
  };

  const getTideList = async () => {
    try {
      if (tideFactors.length) return;
      const { factors } = await getTideFactors();
      dispatch(initTideFactors(factors));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getRegions();
    getTideList();
    // get current route path
    const routePath = router.pathname;

    // when url is "/console", redirect default console app page
    if (routePath === "/console/" || routePath === "/console")
      router.replace(`/console/${DEFAULT_CONSOLE_APP}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout sidebar={<ConsoleSidebar />}>
      <AppLayout routes={routes[appType as string] || []}>{children}</AppLayout>
    </Layout>
  );
}
