import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineDocumentPlus, HiOutlineDocumentCheck } from "react-icons/hi2";

interface UploadBoxProps {
  // shoule RefObject of react, must set to any here to avoid
  // main-app build error
  fileRef: any;
  id: string;
  required?: boolean;
}

export default function UploadBox(props: UploadBoxProps) {
  const [selected, setSelected] = useState(false);
  const [folderName, setFolderName] = useState("");
  const { t } = useTranslation();

  const selectFolder = (event: React.ChangeEvent) => {
    event.preventDefault();
    if (event.target instanceof HTMLInputElement) {
      var files = event.target.files;

      if (files && files.length) {
        var relativePath = files[0].webkitRelativePath;
        var folder = relativePath.split("/")[0];
        setSelected(true);
        setFolderName(folder);
      } else {
        setSelected(false);
        setFolderName("");
      }
    }
    return null;
  };

  useEffect(() => {
    if (props.fileRef.current) {
      // typescript does not allow setting these attributes directly
      // thus set them in useEffect
      // reference: https://stackoverflow.com/questions/63809230/reactjs-directory-selection-dialog-not-working
      props.fileRef.current.setAttribute("directory", "");
      props.fileRef.current.setAttribute("webkitdirectory", "");
      props.fileRef.current.setAttribute("mozdirectory", "");
    }
  }, [props.fileRef]);

  return (
    <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
      <div className="space-y-1 text-center" id="select-box">
        {!selected && (
          <HiOutlineDocumentPlus className="mx-auto h-12 w-12 text-gray-400" />
        )}
        {selected && (
          <HiOutlineDocumentCheck className="mx-auto h-12 w-12 text-gray-400" />
        )}
        {selected && <span>{t("selectedFolder") + folderName}</span>}
        <div className="flex text-sm justify-center text-gray-600">
          <label
            id="file-upload-label"
            htmlFor={props.id}
            className="cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500"
          >
            {!selected && <span>{t("selectFolder")}</span>}
            {selected && <span>{t("reselect")}</span>}
            <input
              ref={props.fileRef}
              id={props.id}
              name="files"
              required={!!props.required}
              type="file"
              className="sr-only relative block opacity-0"
              onChange={selectFolder}
            />
          </label>
        </div>
        {!selected && (
          <p className="text-xs text-gray-500">{t("onlyFolders")}</p>
        )}
      </div>
    </div>
  );
}
