import { Layout } from "@components/common";
import AppLayout from "@components/common/Layout/AppLayout";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import AdminSidebar from "@components/admin/Sidebar";

export default function AdminLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();

  const { current: routes } = useRef([
    { path: "/admin/account", breadcrumb: t("admin.account.name") },
    { path: "/admin/bill", breadcrumb: t("billing.header.bill") },
    { path: "/admin/development", breadcrumb: t("development.header.name") },
    { path: "/admin/refund", breadcrumb: "退款" },
  ]);

  return (
    <Layout sidebar={<AdminSidebar />}>
      <AppLayout routes={routes}>{children}</AppLayout>
    </Layout>
  );
}
