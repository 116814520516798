import { Fragment, useState, useEffect, useRef } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { HiOutlineFunnel } from "react-icons/hi2";
import { classNames } from "@src/utils";
import Button from "./Button";
import { useTranslation } from "react-i18next";

export interface FilterItem {
  text: JSX.Element | string;
  value: string | number | boolean;
}

export interface FilterDropdownProps {
  className?: string;
  iconClassName?: string;
  optionsClassName?: string;
  filterMultiple: boolean;
  filters: FilterItem[];
  defaultFilteredValue?: FilterItem["value"][];
  onFilter(value: FilterItem["value"][]): any;
}

export default function FilterDropdown({
  className = "",
  iconClassName = "",
  optionsClassName = "",
  filterMultiple,
  filters,
  defaultFilteredValue,
  onFilter,
}: FilterDropdownProps) {
  const [values, setValues] = useState<FilterItem["value"][]>(
    defaultFilteredValue || []
  );
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const filterRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const handleGlobalClick: EventListener = (event) => {
    if (
      !popupRef.current ||
      popupRef.current.contains(event.target as HTMLElement)
    )
      return;
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleGlobalClick);
    onFilter(values);
    return () => document.removeEventListener("click", handleGlobalClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (item: FilterItem) => {
    if (filterMultiple) {
      setValues(
        values.includes(item.value)
          ? values.filter((value) => value !== item.value)
          : [...values, item.value]
      );
    } else {
      setValues(values[0] !== item.value ? [item.value] : []);
    }
  };

  const handleSubmit = () => {
    open && onFilter(values);
    setOpen(!open);
  };

  const getPopPosition = () => {
    const position = filterRef.current?.getBoundingClientRect();
    if (!position) return {};
    return { top: `${position.top + 20}px`, left: `${position.left - 128}px` };
  };

  return filters.length ? (
    <div ref={filterRef} className={`inline-block ${className}`}>
      <HiOutlineFunnel
        className={`h-4 w-4 text-gray-500 hover:text-gray-800 cursor-pointer transition-colors duration-300 ${
          values.length ? "!text-blue-600 " : ""
        }${iconClassName}`}
        onClick={handleSubmit}
      />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" onClose={handleSubmit}>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition duration-100 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              ref={popupRef}
              className={`fixed z-50 mt-1 max-h-60 w-fit overflow-auto rounded-md bg-white p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${optionsClassName}`}
              style={getPopPosition()}
            >
              {filters.map((item, index) => (
                <div
                  key={index}
                  className={classNames(
                    values.includes(item.value) ? "bg-blue-50" : "",
                    " rounded-md flex items-center relative cursor-default select-none py-2 px-4 text-gray-900 mb-1 hover:bg-blue-100 transition-colors duration-300"
                  )}
                  onClick={() => handleChange(item)}
                >
                  <input
                    type="checkbox"
                    className="mr-1 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6"
                    readOnly
                    checked={values.includes(item.value)}
                  />
                  {item.text}
                </div>
              ))}
              <div className="flex justify-between items-center border-t p-1 pb-0">
                <Button
                  className="w-16"
                  type="link"
                  onClick={() => setValues([])}
                >
                  {t("common.action.reset")}
                </Button>
                <Button className="!py-1 !px-1 w-16" onClick={handleSubmit}>
                  {t("common.action.confirm")}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </div>
  ) : (
    <></>
  );
}
