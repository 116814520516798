import DialogContainer from "./DialogContainer";
import { useTranslation } from "react-i18next";
import Table from "./Table";

interface hyperParameter extends Record<string, any> {
  name: string;
  type: string;
  defaultValue: any;
  choices?: any[];
  description: string;
}

interface ProjectParamsDialogProps {
  open: boolean;
  setOpen(value: boolean): any;
  hyperParameters: hyperParameter[];
}

const columns = [
  { title: "Name", dataIndex: "name" },
  {
    title: "Required",
    dataIndex: "required",
    render: (record: Record<string, any>) =>
      record.required ? "true" : "false",
  },
  { title: "Type", dataIndex: "type" },
  {
    title: "Default value",
    dataIndex: "defaultValue",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
];

export default function ProjectParamsDialog({
  open,
  setOpen,
  hyperParameters,
}: ProjectParamsDialogProps) {
  const { t } = useTranslation();

  return (
    <DialogContainer
      className="max-h-[80vh] min-w-[50%]"
      open={open}
      setOpen={setOpen}
    >
      <label className="block font-medium text-gray-700 text-base">
        {t("common.header.hyperparameters")}
      </label>
      <div className="rounded-sm bg-gray-200 my-2 p-2 overflow-auto">
        {hyperParameters ? (
          <Table columns={columns} dataSource={hyperParameters} rowKey="name" />
        ) : (
          "This project does not contain HyperParameters.json"
        )}
      </div>
    </DialogContainer>
  );
}
