interface MainContainerProps {
  children: JSX.Element | JSX.Element[];
}

export default function MainContainer({ children }: MainContainerProps) {
  return (
    <div className="h-full w-full mx-auto py-6 px-8 flex flex-col">
      {children}
    </div>
  );
}
