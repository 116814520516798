import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotiTypes } from "../Notifications";
import UploadProcess from "./UploadProcess";
import FileViewDialog from "./FileViewDialog";
import ExplorerHeader from "./ExplorerHeader";
import ExplorerTable from "./ExplorerTable";
import { PathContentObject, FileObject } from "./interface";
import {
  UploadTrackerProps,
  type onUploadedFunctionProps,
} from "@src/utils/api/upload/track";
import { UploadingFileItem } from "@src/utils/api/upload/upload";
import { createUploadTracker } from "@src/utils/api/upload/track";
import { sendErrorNotification, sendFailureNotification } from "@src/utils";
import { allowedFileType } from "./FileViewDialog";
import { useAppDispatch } from "@src/store";

export interface ReuploadFilesFunctionInterface {
  (
    index: number,
    uploadTracker: UploadTrackerProps,
    version: number
  ): Promise<any>;
}

export interface UploadFilesFunctionInterface {
  (
    destinationPath: string,
    files: FileList,
    uploadTracker: UploadTrackerProps,
    version: number
  ): Promise<any>;
}

export interface CreateFolderFunctionInterface {
  (dirName: string, version: number): Promise<any>;
}

export interface DeleteFilesFunctionInterface {
  (filePaths: string[], folders: string[], version: number): Promise<any>;
}

export interface DownloadFilesFunctionInterface {
  (filePath: string, fileName: string, version: number): Promise<any>;
}

export interface GetFileContentFunctionInterface {
  (filePath: string, version: number): Promise<string>;
}

export interface GetFileListFunctionInterface {
  (path: string, version: number): Promise<PathContentObject>;
}

export interface ExplorerProps {
  getFiles: GetFileListFunctionInterface;
  uploadFiles?: UploadFilesFunctionInterface;
  reuploadFiles?: ReuploadFilesFunctionInterface;
  createFolder?: CreateFolderFunctionInterface;
  deleteFiles?: DeleteFilesFunctionInterface;
  downloadFile?: DownloadFilesFunctionInterface;
  getFileContent?: GetFileContentFunctionInterface;
  allowRefresh?: boolean;
  version: number;
}

/**
 * For convenience, root path is start with "root/". However, the backend assumes root path has no prefix.
 * We should remove "root/" from root path when interacting with backend.
 * @param root
 */
function stripRoot(root: string) {
  return root.replace(/^root\/?/, "");
}

// Explorer provides the following functions
// 1. list files or directories
// 2. upload files
// 3. download files
// 4. delete files
// 5. view the content of a file
export default function Explorer({
  getFiles,
  deleteFiles,
  uploadFiles,
  reuploadFiles,
  createFolder,
  downloadFile,
  getFileContent,
  allowRefresh = false,
  version,
}: ExplorerProps) {
  // File Browsing related states
  const [files, setFiles] = useState<FileObject[]>([]);
  const [root, setRoot] = useState<string>("root");

  // File Content Viewing related states
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);
  const [fileContent, setFileContent] = useState("");
  const [fileName, setFileName] = useState<string>("plaintext");

  // Uploading related states
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingFilesList, setUploadingFilesList] = useState<
    UploadingFileItem[]
  >([]);
  const [lastProgressEvent, setLastProgressEvent] = useState<ProgressEvent>();
  const [uploadTracker, setUploadTracker] = useState<UploadTrackerProps>(
    createUploadTracker()
  );

  // General states
  const [selected, setSelected] = useState<FileObject[]>([]);
  const rootRef = useRef(root);
  rootRef.current = root;

  const didMountRef = useRef(false);

  // Common hooks
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  /*
  =======================
  File Browsing
  =======================
  */
  function changeDirHandler(path: string) {
    if (path !== root) {
      setRoot(path);
      setSelected([]);
    }
  }

  function changeDirRelativeHandler(name: string) {
    const path = [root, name].join("/");
    changeDirHandler(path);
  }

  const loadFiles = async (path: string) => {
    // This function will call the getFiles function provided by the user
    // to get the files and folders under the given path.
    const data: PathContentObject = await getFiles(path, version);
    const ret: FileObject[] = [];

    // push folder to ret
    for (let dirName of data.folders) {
      ret.push({
        name: dirName,
        size: "",
        modifiedTime: "",
        type: "directory",
      });
    }

    // push file to ret
    for (let file of data.files) {
      ret.push({
        name: file.name,
        size: file.size,
        modifiedTime: file.lastModified,
        type: "file",
      });
    }

    return Promise.resolve(ret);
  };

  /*
  =======================
  File Content Viewing
  =======================
  */
  async function onOpenFile(filename: string) {
    // Check the extension of the file
    const ext = filename.split(".").pop()?.toLowerCase();

    // if the file type is not allowed, show a notification
    if (!allowedFileType.includes(ext!)) {
      sendFailureNotification(
        t("component.common.explorer.notification.fileTypeNotAllowed", {
          fileType: ext,
        }),
        dispatch
      );
      return;
    }

    if (!getFileContent) {
      sendFailureNotification(
        t("component.common.explorer.notification.getFileContentNotGiven"),
        dispatch
      );
      return;
    }

    try {
      // get the file content
      // and render it in a dialog
      const filePath = stripRoot([root, filename].join("/"));
      const fileContent = await getFileContent!(filePath, version);
      setFileContent(fileContent);

      // get file extension
      setFileName(filename);
      setIsFileDialogOpen(true);
    } catch (error) {
      sendErrorNotification(error, dispatch);
    }
  }

  /*
  =======================
  File Uploading
  =======================
  */
  // avoid to refresh file list high frequency
  // when upload too many files
  const debounceGetFiles = (function () {
    let timer: any = null;
    return function (path: string) {
      return new Promise<FileObject[]>((resolve, reject) => {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          try {
            const data = await loadFiles(path);
            resolve(data);
          } catch (error) {
            sendErrorNotification(error, dispatch);
          }
        }, 1000);
      });
    };
  })();

  const uploadProgressCallback: onUploadedFunctionProps = async (
    e: ProgressEvent | null,
    uploadingFile: UploadingFileItem,
    refresh: boolean
  ) => {
    if (e) {
      uploadingFile.loadedSize = e.loaded;
      setLastProgressEvent(e);

      e.loaded >= e.total && delete uploadingFile.xhr;
    }

    if (refresh) {
      // refresh files after upload success
      const fileList: any[] = await debounceGetFiles(
        stripRoot(rootRef.current)
      );
      setFiles(fileList);
    }
  };

  const onUpload = async (input: HTMLInputElement) => {
    // if uploadFiles is not given, show a notification
    if (!uploadFiles) {
      sendFailureNotification(
        t("component.common.explorer.notification.uploadFilesNotGiven"),
        dispatch
      );
      return;
    }

    // get the files and the number of files for uploading
    const files = input!.files;
    const fileCount = files?.length || 0;

    if (files && fileCount > 0) {
      setIsUploading(true);

      // create tracker
      const uploadTracker = createUploadTracker();
      setUploadTracker(uploadTracker);
      uploadTracker.onUploaded = uploadProgressCallback;
      uploadTracker.setUploadingFilesList = setUploadingFilesList;

      try {
        await uploadFiles(stripRoot(root), files, uploadTracker, version);
      } catch (error) {
        sendErrorNotification(error, dispatch);
        setIsUploading(false);
      }

      // input's value needs to be cleaned up
      // in case of that no files are selected by the input element
      input && (input.value = "");
    }
  };

  const onReupload = async (index: number) => {
    if (!reuploadFiles) {
      sendFailureNotification(
        t("component.common.explorer.notification.reuploadFilesNotGiven"),
        dispatch
      );
      return;
    }

    // create tracker
    try {
      await reuploadFiles(index, uploadTracker, version);

      // refresh after reupload
      const fileList: any[] = await debounceGetFiles(
        stripRoot(rootRef.current)
      );
      setFiles(fileList);
    } catch (error) {
      sendErrorNotification(error, dispatch);
    }
  };

  /*
  =======================
  Folder Creating
  =======================
  */
  const onCreateFolder = async (folderName: string) => {
    const folderPath = stripRoot([rootRef.current, folderName].join("/"));
    await createFolder!(folderPath, version);
    setFiles(await loadFiles(stripRoot(root)));
  };

  /*
  =======================
  File Downloading
  =======================
  */
  const onDownload = () => {
    if (!downloadFile) {
      sendFailureNotification(
        t("component.common.explorer.notification.downloadFilesNotGiven"),
        dispatch
      );
      return;
    } else {
      // iteratively download the selected files
      try {
        for (let fileObj of selected) {
          recursiveDownloadFiles(fileObj, root);
        }
        setFiles([...files]);
      } catch (error) {
        sendErrorNotification(error, dispatch);
      }
    }
  };

  // define a function to recursively download files
  const recursiveDownloadFiles = (fileObj: FileObject, rootPath: string) => {
    if (!downloadFile) return;

    const fileName = fileObj.name;
    const fileType = fileObj.type;

    if (fileType === "file") {
      const filePath = stripRoot([rootPath, fileName].join("/"));
      downloadFile(filePath, fileName, version);
    } else {
      // download folder is not supported for now
      // // get folder path
      // const folderPath = stripRoot([rootPath, fileName].join("/"));
      // // download folder
      // const data: FileFolderObject = await getFiles(
      //   containerId,
      //   folderPath
      // );
      // // convert data to FileObject[]
      // const ret: FileObject[] = [];
      // for (let dirName of data.folders) {
      //   ret.push({
      //     name: dirName,
      //     size: "",
      //     modifiedTime: "",
      //     type: "directory",
      //   });
      // }
      // for (let file of data.files) {
      //   ret.push({
      //     name: file.name,
      //     size: file.size,
      //     modifiedTime: file.lastModified,
      //     type: "file",
      //   });
      // }
      // // iterate files in folder
      // for (let fileObj of ret) {
      //   await recursiveDownloadFiles(fileObj, folderPath);
      // }
    }
  };

  /*
  =======================
  File Deleting
  =======================
  */
  const onDelete = async (fileInputElement: HTMLInputElement) => {
    if (!deleteFiles) {
      dispatch({
        title: t("component.common.explorer.notification.deleteFilesNotGiven"),
        type: NotiTypes.FAIL,
      });
      return;
    } else {
      try {
        const filePaths: string[] = [];
        const folders: string[] = [];
        selected.forEach((item) => {
          const path = stripRoot([root, item.name].join("/"));
          if (item.type === "file") {
            filePaths.push(path);
          } else if (item.type === "directory") {
            folders.push(path);
          }
        });

        await deleteFiles(filePaths, folders, version);

        // input's value need be cleaned up
        fileInputElement.value = "";
        setFiles(await loadFiles(stripRoot(root)));
        setSelected([]);
      } catch (error) {
        sendErrorNotification(error, dispatch);
      }
    }
  };

  /*
  =======================
  Initialization
  =======================
  */
  const init = async () => {
    try {
      // fetch the content in the given path
      const data = await loadFiles(stripRoot(root));
      if (!data) return;

      // set the content to the state
      setFiles(data);
    } catch (error) {
      sendErrorNotification(error, dispatch);
    }
  };

  useEffect(() => {
    if (didMountRef.current) init();
    else didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root]);

  useEffect(() => {
    if (root === "root") init();
    else setRoot("root");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  return (
    <div className="mt-6">
      <UploadProcess
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        uploadingFilesList={uploadingFilesList}
        progressEvent={lastProgressEvent}
        onReupload={onReupload}
      />

      {/* Explorer provides buttons for the user to download/upload/delete files */}
      <ExplorerHeader
        isUploading={isUploading}
        root={root}
        selected={selected}
        onChangeDir={changeDirHandler}
        onDelete={deleteFiles ? onDelete : undefined}
        onDownload={downloadFile ? onDownload : undefined}
        onUpload={uploadFiles ? onUpload : undefined}
        onCreateFolder={createFolder ? onCreateFolder : undefined}
        onRefresh={allowRefresh ? init : undefined}
      />

      {/* Explorer Table will take charge of file system browsing and file content viewing */}
      <ExplorerTable
        files={files}
        // selected={selected}
        setSelected={setSelected}
        onChangeDir={changeDirRelativeHandler}
        onOpenFile={onOpenFile}
      />
      <FileViewDialog
        open={isFileDialogOpen}
        setOpen={setIsFileDialogOpen}
        filename={fileName}
        fileContent={fileContent}
      />
    </div>
  );
}
