import { Fragment } from "react";
import { Transition, Listbox } from "@headlessui/react";

export interface ActionItem {
  name: string;
  onClick: () => any;
  disabled?: boolean;
}

export interface MoreActionsProps {
  title: string;
  actions: ActionItem[];
  inputClass?: string; // input tailwind class name
  optionsClass?: string; // options tailwind class name
}

export default function MoreActions({
  title,
  actions,
  inputClass = "",
  optionsClass = "",
}: MoreActionsProps) {
  return (
    <Listbox>
      {({ open }) => (
        <div className="relative w-fit inline-block">
          <Listbox.Button
            className={`inline-flex items-center text-blue-600 hover:text-blue-500 transition-colors duration-300 font-normal ${inputClass}`}
          >
            <span className="block truncate flex-1 text-start">{title}</span>
          </Listbox.Button>

          {!!actions.length && (
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-in duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`min-w-fit absolute right-0 z-10 mt-1 max-h-60 w-25 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${optionsClass}`}
              >
                {actions.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={` font-normal relative select-none py-2 pl-3 pr-9 transition-colors block truncate ${
                      option.disabled
                        ? "cursor-not-allowed text-gray-400"
                        : "cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-gray-100"
                    }`}
                    value=""
                    onClick={option.onClick}
                    disabled={option.disabled}
                  >
                    {option.name}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          )}
        </div>
      )}
    </Listbox>
  );
}
