import type { DateRange } from "react-day-picker";
import { useState } from "react";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { isSameMonth } from "date-fns";

interface MonthSelectorRangeProps {
  mode: "range";
  selected: DateRange;
  onSelect(val: DateRange): any;
}

interface MonthSelectorSingleProps {
  mode: "single";
  selected: Date;
  onSelect(val: Date): any;
}

export default function MonthSelector({
  mode,
  selected,
  onSelect,
}: MonthSelectorRangeProps | MonthSelectorSingleProps) {
  const { t } = useTranslation();
  const [year, setYear] = useState(new Date().getFullYear());

  const genMonthEl = () => {
    const monthEl = [];
    for (let i = 1; i <= 12; ++i) {
      const date = new Date(`${year}-${i}`);
      let handleClick;
      let colorClass;
      if (mode === "range") {
        if (
          isSameMonth(date, selected.from!) ||
          isSameMonth(date, selected.to!)
        ) {
          handleClick = () => onSelect({ from: date, to: date });
          colorClass = "bg-blue-600 text-white font-bold";
        } else if (date < selected.from!) {
          handleClick = () => onSelect({ from: date, to: selected.to });
          colorClass = "hover:bg-indigo-100";
        } else if (date < selected.to!) {
          handleClick = () => onSelect({ from: selected.from, to: date });
          colorClass = "bg-blue-400 text-white";
        } else {
          handleClick = () => onSelect({ from: selected.from, to: date });
          colorClass = "hover:bg-indigo-100";
        }
      } else {
        handleClick = () => onSelect(date);
        colorClass = isSameMonth(date, selected)
          ? "bg-blue-600 text-white font-bold"
          : "hover:bg-indigo-100";
      }

      monthEl.push(
        <div
          key={i}
          className={`cursor-pointer inline-flex justify-center items-center h-[30px] rounded-md transition-colors duration-300 ${colorClass}`}
          onClick={handleClick}
        >
          {t(`component.common.date.month.${i}`)}
        </div>
      );
    }
    return monthEl;
  };

  return (
    <div className="w-fit h-fit mt-4 shadow rounded p-3 relative z-50 bg-white">
      <div className="flex justify-between text-lg font-bold w-[266px] mb-4">
        {year}
        <div className="flex items-center">
          <div
            className="inline-flex justify-center items-center h-[30px] w-[30px] hover:bg-indigo-100 rounded-md transition-colors duration-300"
            onClick={() => setYear(year - 1)}
          >
            <HiChevronDoubleLeft className="inline-block h-6 w-6" />
          </div>
          <div
            className="inline-flex justify-center items-center h-[30px] w-[30px] hover:bg-indigo-100 rounded-md transition-colors duration-300"
            onClick={() => setYear(year + 1)}
          >
            <HiChevronDoubleRight className="inline-block h-6 w-6" />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-x-2 gap-y-2">{genMonthEl()}</div>
    </div>
  );
}
