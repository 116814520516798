import { type Item } from "@components/common/Dropdown";
import { DialogContainer, Table, Button } from "@components/common";
import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { AlertDialog } from "@components/common";

interface VersionOptDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  versions: Item[];
  onCreate(baseVersion: number): any;
  onDelete(baseVersion: number): any;
}

export default function VersionOptDialog({
  open,
  setOpen,
  name,
  versions,
  onCreate,
  onDelete,
}: VersionOptDialogProps) {
  const { t } = useTranslation();

  const [createAlertOpen, setCreateAlertOpen] = useState(false);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);

  const [selectedVersion, setSelectedVersion] = useState<Item>();

  const tableColumns = [
    {
      title: t(`common.header.version.text`),
      dataIndex: "name",
      width: 100,
    },
    {
      title: t("common.header.actions"),
      width: 100,
      render: (version: Item) => (
        <>
          <Button
            type="link"
            className="mr-2"
            onClick={() => {
              setSelectedVersion(version);
              setCreateAlertOpen(true);
            }}
          >
            {t("common.action.create")}
          </Button>
          {Number(version.value) > 1 && (
            <Button
              type="link"
              onClick={() => {
                setSelectedVersion(version);
                setDeleteAlertOpen(true);
              }}
            >
              {t("common.action.delete")}
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <DialogContainer
        open={open}
        className="max-h-[80vh] min-w-[50%]"
        setOpen={setOpen}
      >
        <div className="text-2xl font-semibold mb-5 flex flex-col items-center px-10">
          {t("common.header.version.management")}
          <Table
            className="mt-3"
            columns={tableColumns}
            dataSource={versions}
          />
        </div>
        <AlertDialog
          open={createAlertOpen}
          setOpen={setCreateAlertOpen}
          title={t("common.header.version.alert.createTitle")}
          description={
            <Trans
              i18nKey="common.header.version.alert.createDesc"
              values={{ name: `${name} ${selectedVersion?.name}` }}
              components={[
                <span key="1" className="font-medium text-gray-900"></span>,
              ]}
            />
          }
          onConfirm={() => {
            selectedVersion && onCreate(selectedVersion?.value as number);
            setCreateAlertOpen(false);
          }}
        />
        <AlertDialog
          open={deleteAlertOpen}
          setOpen={setDeleteAlertOpen}
          title={t("common.header.version.alert.deleteTitle")}
          description={
            <Trans
              i18nKey="app.deleteAlert.description"
              values={{ name: `${name} ${selectedVersion?.name}` }}
              components={[
                <span key="1" className="font-medium text-gray-900"></span>,
              ]}
            />
          }
          onConfirm={() => {
            selectedVersion && onDelete(selectedVersion?.value as number);
            setDeleteAlertOpen(false);
          }}
        />
      </DialogContainer>
    </>
  );
}
