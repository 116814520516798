import { FadeIn } from "../Animation/FadeIn";

interface InfoSectionProps {
  title: JSX.Element | string;
  children: JSX.Element | JSX.Element[];
}

export default function InfoSection({ title, children }: InfoSectionProps) {
  return (
    <div className="mt-5">
      <FadeIn>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </h3>
        </div>
        <div className="mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">{children}</dl>
        </div>
      </FadeIn>
    </div>
  );
}
