import { Layout } from "@components/common";
import AppLayout from "@components/common/Layout/AppLayout";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import BillingSidebar from "@components/billing/BillingSidebar";

export default function BillingLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();

  const { current: routes } = useRef([
    { path: "/billing/overview", breadcrumb: t("billing.overview.title") },
    { path: "/billing/topup", breadcrumb: t("billing.header.topUp") },
    {
      path: "/billing/topup/cashdesk",
      breadcrumb: t("billing.header.cashDesk"),
    },
    {
      path: "/billing/bill",
      breadcrumb: t("billing.header.bill"),
    },
    {
      path: "/billing/voucher",
      breadcrumb: t("billing.header.voucher"),
    },
  ]);

  return (
    <Layout sidebar={<BillingSidebar />}>
      <AppLayout routes={routes}>{children}</AppLayout>
    </Layout>
  );
}
