import MainContainer from "../MainContainer";
import BreadCrumbs from "../BreadCrumbs";
// import useBreadcrumbs from "use-react-router-breadcrumbs";

export interface Route {
  path: string;
  breadcrumb: string;
}

interface AppLayoutProps {
  routes: Route[];
  children: any;
}

export default function AppLayout({ routes, children }: AppLayoutProps) {
  return (
    <MainContainer>
      <BreadCrumbs routes={routes} />
      <div className="mt-4 flex-1 h-0 w-full">{children}</div>
    </MainContainer>
  );
}
