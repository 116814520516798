import { type ReactNode } from "react";

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt?: string;
  title?: string;
}

export default function Image({ alt, ...props }: ImageProps) {
  const handleError: React.ReactEventHandler<HTMLImageElement> = (
    imgEl: any
  ) => {
    const target = imgEl.currentTarget;
    target.classList.add("max-h-12", "max-w-[3rem]");
    target.src = "/imageBroken.svg";
    target.onerror = null;
  };

  return <img onError={handleError} alt={alt} {...props} />;
}
