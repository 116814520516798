import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { Sidebar } from "@components/common";
import type { MenuItem } from "@components/common";
import {
  HiOutlineChartBarSquare,
  HiOutlineWallet,
  HiOutlineClipboardDocumentList,
  HiOutlineTicket,
} from "react-icons/hi2";

export default function BillingSidebar() {
  const { t } = useTranslation();
  const router = useRouter();

  const items: MenuItem[] = [
    {
      type: "item",
      key: "/billing/overview",
      text: t("billing.header.overview"),
      icon: HiOutlineChartBarSquare,
    },
    {
      type: "item",
      key: "/billing/topup",
      text: t("billing.header.topUp"),
      icon: HiOutlineWallet,
    },
    {
      type: "item",
      key: "/billing/bill",
      text: t("billing.header.bill"),
      icon: HiOutlineClipboardDocumentList,
    },
    {
      type: "item",
      key: "/billing/voucher",
      text: t("billing.header.voucher"),
      icon: HiOutlineTicket,
    },
  ];

  const handleNavigate = (item: Record<string, any>) => {
    router.push(item.key);
  };

  return (
    <Sidebar
      title={t("billing.header.name")!}
      selectedKeys={[router.pathname.split("/").slice(0, 3).join("/")]}
      menu={items}
      onClick={handleNavigate}
    />
  );
}
