import { HiOutlineDocument, HiOutlineFolder } from "react-icons/hi2";
import { Button } from "@components/common";

interface FileItemProps {
  name: string;
  type: "file" | "directory";
  className?: string;
  onOpen?(e: React.MouseEvent<HTMLButtonElement>): any;
}

// FileItem is a component that represents a file or a directory in the explorer.
export default function FileItem({
  name,
  type,
  className = "",
  onOpen,
}: FileItemProps) {
  const Icon = type === "file" ? HiOutlineDocument : HiOutlineFolder;

  return (
    <>
      {onOpen ? (
        <Button type="link" onClick={onOpen} className={`flex ${className}`}>
          <Icon className="w-5 h-5 mr-1" />
          {name}
        </Button>
      ) : (
        <span className={`flex ${className}`}>
          <Icon className="w-5 h-5 mr-1" />
          {name}
        </span>
      )}
    </>
  );
}
