export interface RadioOption<T = string> {
  label: JSX.Element | string | number;
  value: T;
  disabled?: boolean;
}

interface RadioProps<T> {
  name?: string;
  className?: string;
  options: RadioOption<T>[];
  value: T;
  onChange: (value: T) => any;
  [key: string]: any;
}

export default function Radio<T>({
  name,
  className = "",
  options,
  value,
  onChange,
  ...otherProps
}: RadioProps<T>) {
  return (
    <div className={`inline-block ${className}`} {...otherProps}>
      <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
        {options.map((option) => (
          <div
            key={option.value as string}
            className={`${
              option.disabled
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer"
            }`}
          >
            <div
              key={option.value as string}
              className={`flex items-center ${
                option.disabled ? " pointer-events-none" : ""
              }`}
              onClick={() => onChange(option.value)}
            >
              <input
                id={option.value as string}
                name={name}
                type="radio"
                readOnly
                checked={option.value === value}
                className={`h-4 w-4 border-gray-300 text-blue-600 transition-colors ${
                  option.disabled ? "" : "cursor-pointer"
                }`}
              />
              <label
                htmlFor={option.value as string}
                className={`ml-3 block text-sm font-medium leading-6 text-gray-900 ${
                  option.disabled ? "" : "cursor-pointer"
                }`}
              >
                {option.label}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
