import { classNames } from "@src/utils";

export interface TooltipProps {
  content: string | React.ReactNode;
  children: React.ReactNode;
  position?: "top" | "bottom";
  className?: string;
  containerClassName?: string;
}

export default function Tooltip({
  content,
  children,
  position = "top",
  containerClassName = "",
  className,
}: TooltipProps) {
  const positionMapping = {
    top: "left-1/2 -translate-x-1/2 after:bottom-1.5 after:border-b after:border-r ",
    bottom:
      "top-full left-1/2 -translate-x-1/2 pt-4 after:top-2.5 after:border-t after:border-l",
  };

  return (
    <div
      className={`relative h-full inline-block group cursor-default ${containerClassName}`}
    >
      {children}
      <div
        className={classNames(
          positionMapping[position],
          "after:bg-white after:absolute after:h-3 after:w-3 after:rotate-45 after:border-gray-200 absolute flex-col bottom-0 pb-3 items-center pointer-events-none  mb-5 group-hover:pointer-events-auto flex w-max max-w-xs group-hover:opacity-100 opacity-0 transition-opacity duration-300 z-10"
        )}
      >
        <span
          className={`relative rounded-md p-4 text-sm leading-2 bg-white border text-gray-600 whitespace-no-wrap shadow-lg ${className}`}
        >
          {content}
        </span>
      </div>
    </div>
  );
}
