import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  HiInboxArrowDown,
  HiXMark,
  HiCheckCircle,
  HiXCircle,
} from "react-icons/hi2";
import { classNames } from "@src/utils";
import { useAppSelector } from "@src/store";

let notificationIdx = 0;

export enum NotiTypes {
  DEFAULT = "default",
  SUCCESS = "success",
  FAIL = "fail",
}

const types = {
  default: { Icon: HiInboxArrowDown, iconClassName: "" },
  success: { Icon: HiCheckCircle, iconClassName: "h-5 w-5 text-green-400" },
  fail: {
    Icon: HiXCircle,
    iconClassName: "h-5 w-5 text-red-400",
  },
};

export interface NotificationProps {
  id?: number;
  title: string;
  text?: string;
  closable?: boolean;
  type?: NotiTypes; // we don't need set the props {Icon} and {iconClassName}, if set the prop {type}
  Icon?(props: React.ComponentProps<"svg">): JSX.Element;
  iconClassName?: string;
  autoCloseTimeoutMs?: number;
}

function Notification({
  id = -1,
  title,
  text,
  closable,
  type,
  Icon,
  iconClassName = (type && types[type].iconClassName) || "",
  autoCloseTimeoutMs = 5000, // if wanna notification not to disappear automatically, set 0
}: NotificationProps) {
  const [show, setShow] = useState(true);
  if (autoCloseTimeoutMs && autoCloseTimeoutMs > 0) {
    setTimeout(() => setShow(false), autoCloseTimeoutMs);
  }

  var MessageIcon;
  if (!Icon) {
    if (type !== undefined && types[type] !== undefined) {
      MessageIcon = types[type].Icon;
    } else {
      MessageIcon = HiInboxArrowDown;
    }
  } else MessageIcon = Icon;

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="notification pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-50">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <MessageIcon
                className={classNames("h-6 w-6", iconClassName)}
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              {text && <p className="mt-1 text-sm text-gray-500">{text}</p>}
            </div>
            {closable && (
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <HiXMark className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default function Notifications() {
  const notifications = useAppSelector((state) => state.common.notifications);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-50 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          {notifications.map((item) => (
            <Notification key={item.id} {...item} />
          ))}
        </div>
      </div>
    </>
  );
}

// manage notifications

export type setNotificationsFunc = React.Dispatch<
  React.SetStateAction<NotificationProps[]>
>;

export function addNotification(
  notification: NotificationProps,
  setNotifications: setNotificationsFunc,
  maxSize: number = 6
) {
  notification.id = notificationIdx;
  // avoid overflow
  notificationIdx = (notificationIdx + 1) % Number.MAX_SAFE_INTEGER;
  // render no more than {maxSize} error messages
  setNotifications((prevNotifications) => [
    ...prevNotifications.slice(-(maxSize - 1)),
    notification,
  ]);
  return notification.id;
}

// export function updateNotification(
//   id: number,
//   newNotification: NotificationProps,
//   setNotifications: setNotificationsFunc
// ) {
//   newNotification.id = id;
//   setNotifications((prevNotifications) =>
//     prevNotifications.map((item) => (item.id === id ? newNotification : item))
//   );
// }

// export function deleteNotification(
//   id: number,
//   setNotifications: setNotificationsFunc
// ) {
//   setNotifications((prevNotifications) =>
//     prevNotifications.filter((item) => item.id !== id)
//   );
// }
