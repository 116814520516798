import type { Mount } from "@src/utils/api/interface";
import { Table } from "@components/common";
import Link from "next/link";
import { useTranslation } from "react-i18next";

export default function MountAssetsTable({ mounts = [] }: { mounts: Mount[] }) {
  const { t } = useTranslation();

  const mountColumns = [
    {
      title: t("development.form.header.assetType"),
      width: 100,
      dataIndex: "type",
    },
    {
      title: t("common.header.name"),
      width: 200,
      render: (mount: Mount) => {
        return (
          <Link href={`/console/${mount.type}/detail/${mount.id}`}>
            <span className="mr-1 text-[#2563EB] hover:opacity-50 transition-opacity duration-300">
              {mount.name || "-"}
            </span>
          </Link>
        );
      },
    },
    {
      title: t("common.header.version.text"),
      width: 100,
      render: ({ version }: Mount) => {
        return version ? `v${version}` : "-";
      },
    },
    {
      title: t("development.form.header.mountPath"),
      width: 300,
      dataIndex: "mountPath",
    },
  ];
  return mounts.length ? (
    <Table
      loading={false}
      columns={mountColumns}
      dataSource={mounts}
      rowKey="id"
    />
  ) : (
    <>-</>
  );
}
