import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { aiServers } from "@src/constant";
import { BreadCrumbs } from "@components/common";
export default function AiServerLayout({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const routes = [
    { path: "/aiserver", breadcrumb: t("aiServer.title") },
    ...aiServers.map(({ id, title }) => ({
      path: `/aiserver/detail/${id}`,
      breadcrumb: title,
    })),
    // { path: "/aiserver/detail/h20", breadcrumb: t("aiServer.h20.title") },
  ];
  return (
    <div className="h-screen overscroll-contain pt-[124px] pb-6">
      <div className="box-border max-w-[1400px] px-10 mx-auto">
        <BreadCrumbs routes={routes} />
      </div>

      <div className="mt-4">
        {children}
        {/* <Route path="/detail/:id" element={<Detail />} /> */}
      </div>
    </div>
  );
}
