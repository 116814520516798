import { useState } from "react";
import DateInput from "./DateInput";
import DatePicker from "./DatePicker";
import type { DateRange } from "react-day-picker";

interface MonthPickerBase {
  min?: number;
  max?: number;
  className?: string;
}

interface MonthPickerRangeProps extends MonthPickerBase {
  mode: "range";
  selected: DateRange; // value in range mode
  onSelect(val: DateRange): any;
}

interface MonthPickerSingleProps extends MonthPickerBase {
  mode: "single";
  selected: Date; // value in single mode
  onSelect(val: Date): any;
}

export default function MonthPicker({
  mode,
  selected,
  onSelect,
  className = "",
}: MonthPickerRangeProps | MonthPickerSingleProps) {
  const [open, setOpen] = useState(false);

  const handleSelect = (value: DateRange | Date | undefined) => {
    onSelect(value as any);
  };

  return (
    <div
      data-testid="month-picker"
      className={`inline-block relative ${className}`}
    >
      <DateInput
        mode={mode}
        picker="month"
        selected={selected as any}
        onClick={() => setOpen(!open)}
      />

      <DatePicker
        picker="month"
        open={open}
        setOpen={setOpen}
        mode={mode as any}
        selected={selected as any}
        onSelect={handleSelect}
      />
    </div>
  );
}
