import { classNames } from "@src/utils";
import { type ReactNode, useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { env } from "@src/constant";

export interface MenuItem {
  name?: string;
  type: "item";
  key: string;
  text: string | ReactNode;
  icon: any;
}

export interface MenuItemGroup {
  name?: string;
  type: "group";
  label: string;
  icon: any;
  children: MenuItem[];
}

export interface SidebarProps {
  title?: string;
  selectedKeys?: string[];
  onClick?: (item: MenuItem) => void;
  menu: (MenuItem | MenuItemGroup)[];
}

const { disableApps } = env;

const filterMenu = (menu: any[]) => {
  const ans: any[] = [];
  menu.forEach((item) => {
    if (item.name && disableApps.includes(item.name)) return;
    if (item.type === "item") ans.push(item);
    else if (item.type === "group") {
      ans.push({ ...item, children: filterMenu(item.children) });
    }
  });
  return ans;
};

export default function Sidebar({
  title,
  selectedKeys = [""],
  onClick,
  menu,
}: SidebarProps) {
  const [collapsed, setCollapsed] = useState(false);
  const [multiLevelMenuCollapsed, setMultiLevelMenuCollapsed] = useState<
    Record<string, boolean>
  >({});

  const renderMenu = filterMenu(menu);

  useEffect(() => {
    // find the multi-level menu
    const menuCollapsed = {} as Record<string, boolean>;

    // iterate through menu and update the submenu collapsed state
    renderMenu.forEach((item) => {
      if (item.type === "group") {
        // let hasSelectedChild = false;

        // // iterate over children, if the key is in selectedKeys,
        // // then set hasSelectedChild to true
        // item.children.forEach((child) => {
        //   if (selectedKeys[0].includes(child.key)) {
        //     hasSelectedChild = true;
        //   }
        // });
        menuCollapsed[item.label] = false;
      }
    });
    setMultiLevelMenuCollapsed(menuCollapsed);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const renderMenuItem = (item: MenuItem) => {
    return (
      <li key={item.key} className="mb-1.5 cursor-pointer">
        <div
          onClick={() => {
            onClick && onClick(item);
          }}
          className={classNames(
            selectedKeys[0].includes(item.key)
              ? "border border-white"
              : "border border-transparent",
            collapsed ? "justify-center" : "justify-start gap-2 px-3",
            "whitespace-nowrap flex items-center rounded-md bg-primary-light/40 py-2 hover:bg-blue-500 hover:border-blue-500 transition-colors duration-300 h-[42px]"
          )}
        >
          <item.icon className="h-4 w-4" />
          <div hidden={collapsed}>{item.text}</div>
        </div>
      </li>
    );
  };

  const handleMultiLevelMenuClick = (item: MenuItemGroup) => {
    const collapsed = multiLevelMenuCollapsed[item.label];
    setMultiLevelMenuCollapsed({
      ...multiLevelMenuCollapsed,
      [item.label]: !collapsed,
    });
  };

  const renderMenuItemGroup = (item: MenuItemGroup) => {
    return (
      <li
        key={item.label}
        className={classNames(
          collapsed ? "group  relative dropdown overflow-visible" : ""
        )}
      >
        {/* parent-menu */}
        <div
          onClick={(e) => {
            e.preventDefault();
            handleMultiLevelMenuClick(item);
          }}
          className={classNames(
            collapsed ? "justify-center" : "justify-between px-3",
            "border border-transparent whitespace-nowrap flex items-center rounded-md bg-primary-light/40 py-2 hover:bg-blue-500 hover:border-blue-500 transition-colors duration-300 h-[42px] cursor-pointer"
          )}
        >
          <div className="flex items-center whitespace-nowrap gap-2">
            <item.icon className="h-4 w-4" />
            <div hidden={collapsed}>{item.label}</div>
          </div>
          <div
            className={classNames(
              "px-2 transition",
              multiLevelMenuCollapsed[item.label] ? "" : "rotate-180"
            )}
            hidden={collapsed}
          >
            <HiChevronDown />
          </div>
        </div>

        {/* child-menu */}
        <div
          className={`text-left text-sm mt-2 w-4/5 mx-auto text-gray-200 overflow-hidden transition-[max-height,opacity] duration-300 ${
            multiLevelMenuCollapsed[item.label]
              ? "max-h-0 opacity-0"
              : "max-h-60  opacity-1"
          }`}
        >
          <ul>
            {item.children.map((child) => {
              return renderMenuItem(child);
            })}
          </ul>
        </div>
      </li>
    );
  };

  return (
    <div
      className={classNames(
        collapsed ? "w-16" : "w-60",
        "h-full bg-gray-800 p-4 antialiased text-white transition-[width] duration-300 flex flex-col"
      )}
    >
      <div
        className={classNames(
          collapsed ? "justify-center" : "justify-between gap-2",
          "flex items-center border-b border-gray-600 pb-2"
        )}
      >
        <div hidden={collapsed}>
          <p className="font-bold text-white whitespace-nowrap">{title}</p>
        </div>

        <div
          className="hover:text-blue-500 cursor-pointer h-6 flex items-center"
          onClick={toggleCollapse}
        >
          {collapsed ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
        </div>
      </div>

      {/* Menu Items */}
      <ul className="mt-4 flex w-full flex-col gap-3 h-0 flex-1 overflow-auto">
        {renderMenu.map((item: MenuItem | MenuItemGroup) => {
          if (item.type === "group") {
            // render this as a two level menu
            return renderMenuItemGroup(item);
          } else if (item.type === "item") {
            // render this single item
            return renderMenuItem(item);
          } else {
            return <></>;
          }
        })}
      </ul>
    </div>
  );
}
