import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { renderInfo } from "@utils/index";

interface InfoSectionProps {
  label: string;
  id?: string; // i18n resource key
  testId?: string;
  value: undefined | string | number | JSX.Element | JSX.Element[];
}

export default function InfoItem({
  label,
  id,
  testId,
  value,
}: InfoSectionProps) {
  const { t } = useTranslation();

  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
      <dt className="text-sm font-medium text-gray-500">{t(label)}</dt>
      <dd
        id={id}
        data-testid={testId}
        className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 whitespace-pre-line"
      >
        {value === undefined ? <Skeleton duration={1} /> : renderInfo(value)}
      </dd>
    </div>
  );
}
