import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import common from "./common";
// ...

const store = configureStore({
  reducer: {
    common,
  },
});

export default store;

// 从 store 本身推断 `RootState` 和 `AppDispatch` 类型
type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
