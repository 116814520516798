import { useRef, useState, Fragment, useEffect } from "react";
import { classNames } from "@utils/index";
import { useTranslation } from "react-i18next";
import { QuestionTooltip } from "@components/common";
import DeleteFilesAlert from "./DeleteFilesAlert";
import CreateFolderDialog from "./CreateFolderDialog";
import { FileObject } from "./interface";
import { HiOutlineFolderOpen } from "react-icons/hi2";

interface ExplorerHeaderProps {
  root: string;
  isUploading: boolean;
  selected: FileObject[];
  onChangeDir(path: string): any;
  onDownload?: () => any;
  onUpload?: (input: HTMLInputElement) => any;
  onCreateFolder?: (folderName: string) => void;
  onDelete?: (input: HTMLInputElement) => any;
  onRefresh?: () => any;
}

export default function ExplorerHeader({
  root,
  isUploading = false,
  selected,
  onChangeDir,
  onDownload,
  onUpload,
  onCreateFolder,
  onDelete,
  onRefresh,
}: ExplorerHeaderProps) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const inputFolderRef = useRef<HTMLInputElement>(null);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  // huawei test
  // const [isModel, setIsModel] = useState(false);

  useEffect(() => {
    if (inputFolderRef.current) {
      inputFolderRef.current.setAttribute("directory", "");
      inputFolderRef.current.setAttribute("webkitdirectory", "");
      inputFolderRef.current.setAttribute("mozdirectory", "");
    }
    // huawei test
    // window.location.pathname.includes("model") && setIsModel(true);
  }, []);

  const directories = root.replace(/\/$/, "").split("/");

  function getFullPath(idx: number) {
    return directories.slice(0, idx + 1).join("/");
  }
  const directoryItems = directories.map((directory, idx) => ({
    name: directory,
    fullPath: getFullPath(idx),
  }));

  return (
    <div className="flex justify-between items-center my-4">
      <div className="flex text-base font-medium text-gray-900">
        <HiOutlineFolderOpen className="w-6 h-6 mr-2" />
        <div data-testid="explorer-paths">
          {directoryItems.map((item, idx) => (
            <Fragment key={item.fullPath}>
              <button
                onClick={() => onChangeDir(item.fullPath)}
                className={classNames(
                  "hover:text-blue-600",
                  idx === directoryItems?.length - 1 ? "text-blue-500" : ""
                )}
              >
                {item.name}
              </button>
              /
            </Fragment>
          ))}
        </div>
      </div>

      {/* Buttons for File Downloading */}
      <div className="isolate inline-flex rounded-md shadow-sm mr-px">
        {!!onRefresh && (
          <button
            type="button"
            className="relative -mr-px inline-flex items-center first:rounded-l-md last:rounded-r-md border border-gray-300 bg-white px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed"
            onClick={onRefresh}
            data-testid="explorer-refresh"
          >
            {t("common.action.refresh")}
          </button>
        )}

        {/* disable only when no files are selected or a selected item is not file */}
        {!!onDownload && (
          <button
            type="button"
            className="relative -mr-px inline-flex items-center first:rounded-l-md last:rounded-r-md border border-gray-300 bg-white px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
            onClick={onDownload}
            disabled={
              !selected ||
              selected.length === 0 ||
              selected.some((item) => item.type !== "file")
            }
            data-testid="explorer-download"
          >
            {t("component.common.explorer.downloadFiles")}
            <QuestionTooltip
              title={t("component.common.explorer.downloadTooltip")}
              position="top"
            />
          </button>
        )}

        {/* Buttons for File Uploading */}
        {!!onUpload && (
          <>
            <button
              type="button"
              className="relative -mr-px inline-flex items-center first:rounded-l-md last:rounded-r-md border border-gray-300 bg-white px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
              onClick={() => inputRef.current!.click()}
              data-testid="explorer-upload"
              disabled={isUploading}
            >
              {t("component.common.explorer.uploadFiles")}
            </button>
            <input
              type="file"
              hidden
              multiple
              ref={inputRef}
              // huawei test
              // accept={isModel ? ".zip" : ""}
              onChange={() => onUpload(inputRef.current!)}
            />
            <button
              type="button"
              className="relative -mr-px inline-flex items-center first:rounded-l-md last:rounded-r-md border border-gray-300 bg-white px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
              onClick={() => inputFolderRef.current!.click()}
              data-testid="explorer-uploadFolder"
              disabled={isUploading}
            >
              {t("component.common.explorer.uploadFolder")}
            </button>
            <input
              type="file"
              hidden
              ref={inputFolderRef}
              onChange={() => onUpload(inputFolderRef.current!)}
            />
          </>
        )}
        {!!onCreateFolder && (
          <>
            <button
              type="button"
              className="relative -mr-px inline-flex items-center first:rounded-l-md last:rounded-r-md border border-gray-300 bg-white px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 "
              onClick={() => setOpenCreateFolder(true)}
            >
              {t("component.common.explorer.createFolder")}
            </button>
            <CreateFolderDialog
              open={openCreateFolder}
              setOpen={setOpenCreateFolder}
              onCreateFolder={onCreateFolder}
            />
          </>
        )}

        {/* Button for File Deletion */}
        {!!onDelete && (
          <>
            <button
              type="button"
              className="relative -mr-px inline-flex items-center first:rounded-l-md last:rounded-r-md border border-gray-300 bg-white px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
              onClick={() => setOpenDeleteAlert(true)}
              disabled={selected?.length === 0}
              data-testid="explorer-delete"
            >
              {t("component.common.explorer.deleteFiles")}
            </button>
            <DeleteFilesAlert
              open={openDeleteAlert}
              setOpen={setOpenDeleteAlert}
              onConfirm={async () => await onDelete(inputRef.current!)}
            />
          </>
        )}
      </div>
    </div>
  );
}
