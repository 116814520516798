import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Sidebar, { MenuItem, MenuItemGroup } from "./Sidebar";
import {
  HiCommandLine,
  HiMiniServerStack,
  HiCircleStack,
  HiUser,
  HiMiniSquares2X2,
} from "react-icons/hi2";
// import { AiFillShop } from "react-icons/ai";
// import { TiFlowMerge } from "react-icons/ti";
// import { useContext } from "react";
//

export default function ConsoleSidebar() {
  const { t } = useTranslation();
  const router = useRouter();
  // const { userInfo } = useAppSelector((state) => state.common);

  const menu: (MenuItem | MenuItemGroup)[] = [
    // {
    //   type: "group",
    //   label: t("common.header.asset"),
    //   icon: HiCircleStack,
    //   children: [
    //     {
    //       name: "dataset",
    //       type: "item",
    //       key: "/console/dataset",
    //       text: t("dataset.header.name"),
    //       icon: HiOutlinePhoto,
    //     },
    //     {
    //       name: "model",
    //       type: "item",
    //       key: "/console/model",
    //       text: t("model.header.name"),
    //       icon: HiOutlineCube,
    //     },
    //     {
    //       name: "project",
    //       type: "item",
    //       key: "/console/project",
    //       icon: HiOutlineFolder,
    //       text: t("project.header.name"),
    //     },
    //     {
    //       name: "template",
    //       type: "item",
    //       key: "/console/template",
    //       icon: HiOutlineSquare3Stack3D,
    //       text: t("template.header.name"),
    //     },

    //   ],
    // },
    // {
    //   name: "resource",
    //   type: "group",
    //   icon: HiMiniServerStack,
    //   label: t("resource.title"),
    //   children: [
    //     {
    //       type: "item",
    //       key: "/console/resource/public",
    //       icon: HiOutlineUserGroup,
    //       text: t("resource.public"),
    //     },
    //     {
    //       type: "item",
    //       key: "/console/resource/private",
    //       icon: HiOutlineUser,
    //       text: t("resource.private"),
    //     },
    //   ],
    // },
    {
      name: "resource",
      type: "item",
      key: "/console/resource",
      icon: HiMiniServerStack,
      text: t("resource.title"),
    },
    {
      name: "development",
      type: "item",
      key: "/console/development",
      icon: HiCommandLine,
      text: t("development.header.name"),
    },
    {
      name: "image",
      type: "item",
      key: "/console/image",
      icon: HiMiniSquares2X2,
      text: t("image.header.name"),
    },
    // {
    //   name: "job",
    //   type: "item",
    //   key: "/console/job",
    //   icon: HiRocketLaunch,
    //   text: t("job.header.name"),
    // },
    // {
    //   name: "inference",
    //   type: "item",
    //   key: "/console/inference",
    //   icon: HiPaperAirplane,
    //   text: t("inference.header.name"),
    // },
    // {
    //   name: "app",
    //   type: "item",
    //   key: "/console/app",
    //   icon: AiFillShop,
    //   text: t("app.header.name"),
    // },
    {
      name: "filestorage",
      type: "item",
      key: "/console/filestorage",
      icon: HiCommandLine,
      text: (
        <div className="flex items-center">
          {t("filestorage.header.name")}
          <span className="text-xs bg-gray-500 px-1 rounded-sm ml-2">Beta</span>
        </div>
      ),
    },
    {
      name: "PublicData",
      type: "item",
      key: "/console/dataset",
      icon: HiCircleStack,
      text: t("publicdata.header.name"),
    },
    {
      name: "User",
      type: "item",
      key: "/console/user",
      icon: HiUser,
      text: t("user.header.name"),
    },
  ];

  // !userInfo?.mainUserId &&
  //   menu.push({
  //     name: "subUser",
  //     type: "item",
  //     key: "/console/subUser",
  //     icon: TiFlowMerge,
  //     text: t("subUser.header.name"),
  //   });

  const navigateOnClick = (item: MenuItem) => {
    router.push(item.key);
  };

  return (
    <Sidebar
      title={t("component.common.consoleSidebar.console") as string}
      selectedKeys={[router.pathname.split("/").slice(0, 4).join("/")]}
      onClick={navigateOnClick}
      menu={menu}
    />
  );
}
