import { Disclosure } from "@headlessui/react";
import Link from "next/link";
import AvatarDropdown from "./AvatarDropdown";
// import MobilePanel from "./MobilePanel";
import NavLogo from "./NavLogo";
import { useTranslation } from "react-i18next";
import { LangsDropdown } from "@components/common";
import i18n from "@src/i18next";
// import { HiOutlineBars3, HiOutlineXMark } from "react-icons/hi2";
// import { NAVBAR_LINKS } from "./constant";
import { useSignOut } from "@utils/hooks";
import { useAppDispatch, useAppSelector } from "@src/store";
import { openContactDialog, setUserInfo } from "@src/store/common";
import UserNotificationDropdown from "./UserNotificationDropdown";
import { useNotificationData, useUser } from "@src/utils/hooks";

interface NavBarProps {
  avatarSrc?: string | undefined;
  onSignout?(): any;
}

export default function NavBar({ avatarSrc, onSignout }: NavBarProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { notificationData, userInfo } = useAppSelector(
    (state) => state.common
  );

  useUser();
  useNotificationData();

  const signOut = useSignOut();

  const handleSignout =
    onSignout ||
    (async () => {
      await signOut();
      dispatch(setUserInfo(null));
    });

  // const navLinks = userInfo?.group?.includes("admin")
  //   ? NAVBAR_LINKS
  //   : NAVBAR_LINKS.slice(0, 2);

  return (
    <Disclosure as="nav" className="fixed w-full top-0 z-50">
      {({ open }) => (
        <>
          <div className="px-8 h-9 bg-blue-500 text-sm text-center text-white leading-9">
            {notificationData ? notificationData["notification"] : ""}
          </div>
          <div className="bg-white px-8 shadow relative">
            <div className=" flex h-16 justify-between">
              <div className="flex px-0">
                <NavLogo />
              </div>

              {/* Search bar */}
              {/* <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
              <div className="w-full max-w-lg lg:max-w-xs">
                <label htmlFor="search" className="sr-only">
                  {t("search")}
                </label>
                <div className="relative">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search"
                    name="search"
                    className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 placeholder-gray-500 focus:border-blue-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
                    placeholder={t("search") as string}
                    type="search"
                  />
                </div>
              </div>
            </div> */}

              <div className="flex">
                {/* Contact us */}
                <div className="flex items-center text-base font-medium">
                  <Link href="/aiserver">
                    <div className="text-gray-500 hover:text-gray-900 transition-colors">
                      {t("aiServer.title")}
                    </div>
                  </Link>
                </div>

                {/* Contact us */}
                <div className="flex items-center text-base font-medium ml-6">
                  <div
                    className="cursor-pointer text-gray-500 hover:text-gray-900 transition-colors"
                    onClick={() => dispatch(openContactDialog(true))}
                  >
                    {t("home.footer.labels.contact")}
                  </div>
                </div>

                {/* Documentation */}
                <div className="flex items-center ml-6">
                  <a
                    className="text-base font-medium text-gray-500 hover:text-gray-900 transition-colors"
                    href={`/doc/docs/intro/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("component.common.navBar.documentation")}
                  </a>
                </div>

                {/* Language Selection */}
                <div className="flex items-center ml-6">
                  <div className="flex h-6 justify-end pr-[20%]">
                    <LangsDropdown i18n={i18n} />
                  </div>
                </div>

                {/* Notification & Avatar Profile */}
                <div className="ml-4 flex items-center">
                  {userInfo?.username ? (
                    <>
                      <UserNotificationDropdown />
                      {/* Profile dropdown */}
                      <AvatarDropdown
                        username={userInfo.username}
                        avatarSrc={avatarSrc}
                        onSignout={handleSignout}
                      />
                    </>
                  ) : (
                    <>
                      <Link
                        href="/account/signup"
                        className="ml-8 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                      >
                        {t("component.common.landingNavBar.actions.signUp")}
                      </Link>
                      <Link
                        href="/account/signin"
                        className="ml-4 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-gradient-to-r from-blue-600 to-blue-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-blue-700 hover:to-blue-700"
                      >
                        {t("component.common.landingNavBar.actions.signIn")}
                      </Link>
                    </>
                  )}
                </div>
              </div>

              {/* <div className="flex items-center lg:hidden"> */}
              {/* Mobile menu button */}
              {/* <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                <span className="sr-only">
                  {t("component.common.navBar.openMenu")}
                </span>
                {open ? (
                  <HiOutlineXMark
                    className="block h-6 w-6"
                    aria-hidden="true"
                  />
                ) : (
                  <HiOutlineBars3
                    className="block h-6 w-6"
                    aria-hidden="true"
                  />
                )}
              </Disclosure.Button>
            </div>*/}
            </div>
          </div>
          {/* <MobilePanel
          username={username ? username : ""}
          email={email ? email : ""}
          avatarSrc={avatarSrc}
          onSignout={handleSignout}
        /> */}
        </>
      )}
    </Disclosure>
  );
}
