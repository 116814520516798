import { classNames } from "@src/utils";
import { useTranslation } from "react-i18next";
import { HiArrowPath } from "react-icons/hi2";

export const StatusColor = {
  YELLOW: "bg-yellow-500",
  GREEN: "bg-green-500",
  BLUE: "bg-blue-500",
  RED: "bg-red-500",
  GRAY: "bg-gray-500",
};

export interface StatusType {
  name: string;
  color: string;
  refreshable?: boolean;
  displayName?: string | JSX.Element;
}

export interface StatusProps {
  loading?: boolean;
  currentStatus?: string;
  statusTypes: StatusType[];
  handleRefresh?: () => void;
  withLabel?: boolean;
}

export default function Status({
  loading = false,
  currentStatus,
  statusTypes,
  handleRefresh,
  withLabel = true,
}: StatusProps) {
  const { t } = useTranslation();

  let status;

  for (const statusType of statusTypes) {
    if (statusType.name === currentStatus) {
      status = statusType;
      break;
    }
  }

  if (!status) {
    status = {
      name: t("common.status.unknown"),
      color: StatusColor.GRAY,
      refreshable: false,
    };
  }

  return (
    <div className="flex items-center">
      {withLabel && (
        <span className="text font-medium mr-1 text-gray-900">
          {`${t("common.header.status")}: `}
        </span>
      )}

      <span className="inline-flex items-center text text-gray-700">
        {/* status */}
        <div className="inline-flex items-center">
          <div
            data-testid="job-status"
            className={classNames(status.color, "rounded-full h-2 w-2 mr-2")}
          />
          {status.displayName ? status.displayName : status.name}
        </div>

        {/* refresh button */}
        {status.refreshable && handleRefresh && (
          <HiArrowPath
            className={classNames(
              loading ? "animate-spin" : "",
              "ml-2 text-base hover:text-[#2563EB] cursor-pointer"
            )}
            data-testid="status-refresh"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              if (loading) return;
              handleRefresh();
            }}
          />
        )}
      </span>
    </div>
  );
}
