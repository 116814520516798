import { useEffect, useRef, Fragment } from "react";
import { Transition } from "@headlessui/react";
import { DayPicker } from "react-day-picker";
import type { SelectRangeEventHandler, DateRange } from "react-day-picker";
import MonthSelector from "./MonthSelector";
import "react-day-picker/dist/style.css";
import { zhCN, enUS } from "date-fns/locale";
import i18n from "@src/i18next";

interface DatePickerBase {
  picker?: "day" | "month";
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  [key: string]: any;
}

interface DatePickerRangeProps extends DatePickerBase {
  mode: "range";
  selected: DateRange;
  onSelect: SelectRangeEventHandler;
}

interface DatePickerSingleProps extends DatePickerBase {
  mode: "single";
  selected: Date;
  onSelect(val: Date): any;
}

export default function DatePicker({
  picker = "day",
  open,
  setOpen,
  mode,
  selected,
  onSelect,
  ...otherProps
}: DatePickerRangeProps | DatePickerSingleProps) {
  const popupRef = useRef<HTMLDivElement>(null);

  const handleGlobalClick: EventListener = (event) => {
    if (
      !popupRef.current ||
      popupRef.current.contains(event.target as HTMLElement)
    )
      return;
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleGlobalClick);
    return () => document.removeEventListener("click", handleGlobalClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Transition
      show={open}
      as={Fragment}
      enter="transition ease-in duration-100"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="w-fit h-fit absolute t-0 l-0 z-50" ref={popupRef}>
        {picker === "day" ? (
          // day picker
          <DayPicker
            locale={{ zh: zhCN, en: enUS }[i18n.language]}
            mode={mode as any}
            selected={selected as any}
            onSelect={onSelect as any}
            {...otherProps}
          />
        ) : (
          // month picker
          <MonthSelector
            mode={mode as any}
            selected={selected as any}
            onSelect={onSelect as any}
          />
        )}
      </div>
    </Transition>
  );
}
