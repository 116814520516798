import { ReactElement } from "react-markdown/lib/react-markdown";
import { useState } from "react";
import {
  HiChevronLeft,
  HiChevronRight,
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiEllipsisHorizontal,
} from "react-icons/hi2";

export interface PaginationProps {
  className?: string;
  current: number; // current page
  defaultPageSize?: number;
  total: number;
  onChange: (current: number, pageSize: number) => void;
}

const basePageClasses =
  "relative inline-flex items-center px-4 py-2 text-sm font-semibold transition-color duration-300 ";

const focusPageClass = "bg-blue-600 z-10 text-white hover:bg-blue-500";

const defaultPageClass =
  "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 bg-white";

export default function Pagination({
  className = "",
  current,
  defaultPageSize = 10,
  total,
  onChange,
}: PaginationProps) {
  const totalPage = Math.ceil(total / defaultPageSize) || 1;

  const handlePageChange = (nextIndex: number) => {
    if (nextIndex < 1 || nextIndex > totalPage) return;
    onChange(nextIndex, defaultPageSize);
  };

  const [hoverLeft, setHoverLeft] = useState(false);
  const [hoverRight, setHoverRight] = useState(false);

  // index === -1: current - 3; index === 0: current + 3;
  const getPageElement = (index: number) => {
    return index !== -1 && index !== 0 ? (
      <span
        key={index}
        onClick={() => handlePageChange(index)}
        className={`${basePageClasses}${
          index === current ? focusPageClass : defaultPageClass
        }`}
      >
        {index}
      </span>
    ) : index === -1 ? (
      <span
        key={index}
        onMouseOver={() => setHoverLeft(true)}
        onMouseOut={() => setHoverLeft(false)}
        onClick={() => handlePageChange(current - 3)}
        className={`${basePageClasses}${defaultPageClass} !px-3`}
      >
        {hoverLeft ? (
          <HiChevronDoubleLeft className="w-5 text-blue-500" />
        ) : (
          <HiEllipsisHorizontal className="w-5 text-gray-500" />
        )}
      </span>
    ) : (
      <span
        key={index}
        onMouseOver={() => setHoverRight(true)}
        onMouseOut={() => setHoverRight(false)}
        onClick={() => handlePageChange(current + 3)}
        className={`${basePageClasses}${defaultPageClass} !px-3`}
      >
        {hoverRight ? (
          <HiChevronDoubleRight className="w-5 text-blue-500" />
        ) : (
          <HiEllipsisHorizontal className="w-5 text-gray-500" />
        )}
      </span>
    );
  };

  const renderPaginations = () => {
    const paginations: ReactElement[] = [];

    if (totalPage <= 7) {
      for (let i = 1; i <= totalPage; ++i) {
        paginations.push(getPageElement(i));
      }
    } else {
      const beginIndex =
        current === 1 ? 1 : current === totalPage ? totalPage - 2 : current - 1;

      beginIndex > 1 && paginations.push(getPageElement(1));
      current >= 4 && paginations.push(getPageElement(-1));

      [beginIndex, beginIndex + 1, beginIndex + 2].forEach((i) =>
        paginations.push(getPageElement(i))
      );

      current <= totalPage - 3 && paginations.push(getPageElement(0));
      beginIndex + 2 < totalPage && paginations.push(getPageElement(totalPage));
    }

    return paginations;
  };

  return (
    <div
      className={`w-full flex items-center justify-center pt-5 ${className}`}
    >
      <div className="w-fit flex items-center justify-between">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm cursor-pointer"
            aria-label="Pagination"
          >
            <span
              className="bg-white relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 transition-colors duration-300"
              onClick={() => handlePageChange(current - 1)}
            >
              <HiChevronLeft className="h-5 w-5" aria-hidden="true" />
            </span>
            {renderPaginations()}
            <span
              className="bg-white relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 transition-colors duration-300"
              onClick={() => handlePageChange(current + 1)}
            >
              <HiChevronRight className="h-5 w-5" aria-hidden="true" />
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
}
