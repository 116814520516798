interface ButtonProps {
  className?: string;
  type?: "button" | "submit" | "reset" | "link" | "text";
  [propName: string]: string | any;
}

export default function Button({
  className = "",
  type = "button",
  children,
  ...other
}: ButtonProps): JSX.Element {
  return type === "link" ? (
    <a
      className={`inline-flex justify-center items-center text-blue-600 hover:text-blue-500 text-sm transition-colors duration-300 cursor-pointer ${className}`}
      {...other}
    >
      {children}
    </a>
  ) : type === "text" ? (
    <span
      className={`inline-flex justify-center items-center text-blue-600 hover:text-blue-500 text-sm transition-colors duration-300 cursor-pointer ${className}`}
      {...other}
    >
      {children}
    </span>
  ) : (
    <button
      type={type}
      className={`inline-flex justify-center items-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 !transition-colors !duration-300 ${className}`}
      {...other}
    >
      {children}
    </button>
  );
}
