import Link from "next/link";
import { Button } from "@components/common";
import "react-loading-skeleton/dist/skeleton.css";

export interface LinkProps {
  Icon: React.ElementType | any;
  text?: string;
  href?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export interface DetailHeaderProps {
  title?: string | React.ReactNode;
  tags?: string[] | null;
  subTitle?: string | React.ReactNode;
  buttons?: (LinkProps | JSX.Element)[];
  rightButtons?: (LinkProps | JSX.Element)[];
}

export default function DetailHeader({
  title,
  // tags,
  // subTitle = "",
  buttons,
  rightButtons,
}: DetailHeaderProps) {
  return (
    // <div>
    <div className="flex justify-between items-center mb-2">
      {!!title && (
        <h1 className="text-2xl font-bold text-gray-900 min-w-[20%] leading-[38px] font-mono flex items-center md:mr-10 sm:mr-6">
          {title}
        </h1>
      )}

      <div className="flex space-x-3">
        {buttons ? (
          buttons.map((item, index) => {
            // if item is LinkProps
            if ((item as LinkProps).href || (item as LinkProps).onClick) {
              const {
                Icon,
                href,
                text,
                onClick,
                disabled = false,
              } = item as LinkProps;

              if (onClick) {
                return (
                  <Button
                    className="whitespace-nowrap"
                    key={index}
                    onClick={onClick}
                    disabled={disabled}
                  >
                    <Icon className="h-5 w-5" />
                    {text && <span className="ml-1">{text}</span>}
                  </Button>
                );
              } else {
                return (
                  <Link key={index} href={href ? href : "#"}>
                    <Button className="whitespace-nowrap">
                      <Icon className="h-5 w-5" />
                      {text && <span className="ml-1">{text}</span>}
                    </Button>
                  </Link>
                );
              }
            } else {
              return <div key={index}>{item as JSX.Element}</div>;
            }
          })
        ) : (
          <></>
        )}
      </div>

      {!!rightButtons && (
        <div className="flex space-x-3">
          {rightButtons.map((item, index) => {
            // if item is LinkProps
            if ((item as LinkProps).href || (item as LinkProps).onClick) {
              const {
                Icon,
                href,
                text,
                onClick,
                disabled = false,
              } = item as LinkProps;

              if (onClick) {
                return (
                  <Button
                    className="whitespace-nowrap"
                    key={index}
                    onClick={onClick}
                    disabled={disabled}
                  >
                    <Icon className="h-5 w-5" />
                    {text && <span className="ml-1">{text}</span>}
                  </Button>
                );
              } else {
                return (
                  <Link key={index} href={href ? href : "#"}>
                    <Button className="whitespace-nowrap">
                      <Icon className="h-5 w-5" />
                      {text && <span className="ml-1">{text}</span>}
                    </Button>
                  </Link>
                );
              }
            } else {
              return <div key={index}>{item as JSX.Element}</div>;
            }
          })}
        </div>
      )}
    </div>
    /* {tags && (
        <div className="mt-2">
          {tags.map((tag) => (
            <div
              key={tag}
              className="inline-block border rounded px-2 text-gray-600 bg-blue-100 mr-2"
            >
              {tag}
            </div>
          ))}
        </div>
      )} */
    /* <p className="mt-2 text-sm text-gray-700">{subTitle}</p> */
    /* </div> */
  );
}
