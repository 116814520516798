import AlertDialog from "@components/common/AlertDialog";
import { useTranslation } from "react-i18next";

interface DeleteFilesAlertProps {
  open: boolean;
  setOpen(value: boolean): any;
  onConfirm?(): any;
}

// DeleteFilesAlert is a component that
// represents a dialog that asks the user to confirm the deletion of files.
export default function DeleteFilesAlert({
  open,
  setOpen,
  onConfirm,
}: DeleteFilesAlertProps) {
  const { t } = useTranslation();
  const description = <>{t("component.common.explorer.deleteFilesAlert")}</>;

  return (
    <AlertDialog
      open={open}
      setOpen={setOpen}
      title={t("component.common.explorer.deleteFiles")}
      description={description}
      buttonText={t("common.action.delete")}
      onConfirm={onConfirm}
    />
  );
}
