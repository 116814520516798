import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Button } from "@components/common";
import { sendErrorNotification } from "@src/utils";
import DialogContainer from "../DialogContainer";
import { useAppDispatch } from "@src/store";

interface CreateFolderDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCreateFolder: (folderName: string) => void;
}

const FOLDER_NAME_VALIDATION = /^[a-zA-Z0-9_-]*$/;

export default function CreateFolderDialog({
  open,
  setOpen,
  onCreateFolder,
}: CreateFolderDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [folderName, setFolderName] = useState("");

  const isError = !FOLDER_NAME_VALIDATION.test(folderName);

  const closeDialog = () => {
    setFolderName("");
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      if (!isError) {
        await onCreateFolder(folderName);
        closeDialog();
      }
    } catch (error) {
      sendErrorNotification(error, dispatch);
    }
  };

  return (
    <DialogContainer open={open} setOpen={closeDialog} className="!w-[540px]">
      <div className="font-medium text-lg mb-6">
        {t("component.common.explorer.createFolder")}
      </div>
      <div className="flex justify-between">
        <span className="text-sm mt-[6px] text-gray-700">
          {t("component.common.explorer.folderName")}
        </span>
        <div>
          <Input
            className="h-8 w-96"
            type="text"
            data-testid="create-folder"
            value={folderName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFolderName(e.target.value)
            }
            isError={isError}
          />
          <div className="text-xs mt-1 text-gray-500">
            {t("component.common.explorer.createFolderTip")}
          </div>
        </div>
      </div>
      <div className="mt-8 float-right">
        <Button
          className="!border-gray-300 bg-white !text-gray-700 hover:bg-gray-50 mr-4"
          onClick={closeDialog}
        >
          {t("common.action.cancel")}
        </Button>
        <Button onClick={handleSubmit} disabled={!folderName || isError}>
          {t("common.action.confirm")}
        </Button>
      </div>
    </DialogContainer>
  );
}
