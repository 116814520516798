import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { type Region, type TideFactor } from "@utils/api/resource";
import { type UserNotificationPullDataResponse } from "@utils/api/account";
import { type UserInfo } from "@utils/hooks";
import { type NotificationProps } from "@components/common/Notifications";

const notiMaxSize = 6;

export const common = createSlice({
  name: "common",
  initialState: {
    isContactDialogOpen: false,
    notificationData: null as null | UserNotificationPullDataResponse,
    regions: [] as Region[],
    tideFactors: [] as TideFactor[],
    notificationIdx: 0,
    notifications: [] as NotificationProps[],
    userInfo: null as null | UserInfo,
    hasReadInstanceAgreement: false,
  },
  reducers: {
    openContactDialog: (state, action: PayloadAction<boolean>) => {
      state.isContactDialogOpen = action.payload;
    },
    initRegions: (state, action: PayloadAction<Region[]>) => {
      state.regions = action.payload;
    },
    initTideFactors: (state, action: PayloadAction<TideFactor[]>) => {
      state.tideFactors = action.payload;
    },
    initNotificationData: (
      state,
      action: PayloadAction<UserNotificationPullDataResponse>
    ) => {
      state.notificationData = action.payload;
    },

    addNotification: (state, action: PayloadAction<NotificationProps>) => {
      const notification = action.payload;
      notification.id = state.notificationIdx;
      state.notificationIdx =
        (state.notificationIdx + 1) % Number.MAX_SAFE_INTEGER;
      state.notifications.length >= notiMaxSize && state.notifications.shift();
      state.notifications.push(notification);
    },
    setUserInfo: (state, action: PayloadAction<null | UserInfo>) => {
      state.userInfo = action.payload;
    },
    setHasReadInstanceAgreement: (state) => {
      state.hasReadInstanceAgreement = true;
    },
  },
});

export const {
  openContactDialog,
  initRegions,
  initTideFactors,
  initNotificationData,
  addNotification,
  setUserInfo,
  setHasReadInstanceAgreement,
} = common.actions;

export default common.reducer;
