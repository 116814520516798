"use client";
import { useState, useEffect } from "react";
import DateInput from "./DateInput";
import DatePicker from "./DatePicker";
import type { DateRange } from "react-day-picker";

// just support range for now
interface DayPickerRangeProps {
  mode: "range";
  selected: DateRange; // value in range mode
  onSelect(val: DateRange): any;
  min?: number;
  max?: number;
  className?: string;
}

export default function DayPicker({
  mode,
  selected,
  onSelect,
  min,
  max,
  className = "",
}: DayPickerRangeProps) {
  const [open, setOpen] = useState(false);

  const handleClear = () => {
    onSelect({ from: undefined, to: undefined });
    setOpen(false);
  };

  const handleSelect = (value: DateRange | undefined) => {
    if (!value) return;
    onSelect(value);
  };

  useEffect(() => {
    if (!selected.from !== !selected.to) handleClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div className={`inline-block ${className}`}>
      <DateInput
        mode={mode}
        selected={selected}
        onClear={handleClear}
        onClick={() => setOpen(!open)}
      />
      <DatePicker
        open={open}
        setOpen={setOpen}
        mode={mode}
        selected={selected}
        onSelect={handleSelect}
        min={min}
        max={max}
      />
    </div>
  );
}
