import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import type { MenuItem } from "@components/common";
import { Sidebar } from "@components/common";
import {
  HiCommandLine,
  HiMiniUserGroup,
  HiOutlineClipboardDocumentList,
  HiCurrencyYen,
} from "react-icons/hi2";

export default function AdminSidebar() {
  const { t } = useTranslation();
  const router = useRouter();

  const items: MenuItem[] = [
    {
      type: "item",
      key: "/admin/account",
      text: t("admin.account.name"),
      icon: HiMiniUserGroup,
    },
    {
      type: "item",
      key: "/admin/bill",
      text: t("billing.header.bill"),
      icon: HiOutlineClipboardDocumentList,
    },
    {
      type: "item",
      key: "/admin/development",
      text: t("development.header.name"),
      icon: HiCommandLine,
    },
    {
      type: "item",
      key: "/admin/refund",
      text: "退款",
      icon: HiCurrencyYen,
    },
  ];

  const handleNavigate = (item: Record<string, any>) => {
    router.push(item.key);
  };

  return (
    <Sidebar
      title={t("admin.title")!}
      selectedKeys={[router.pathname.split("/").slice(0, 3).join("/")]}
      menu={items}
      onClick={handleNavigate}
    />
  );
}
