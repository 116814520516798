import { classNames } from "@src/utils";
import { Dispatch, SetStateAction, useState } from "react";

export interface TabItem extends Record<string, any> {
  key: string;
  label: JSX.Element | string;
  children?: JSX.Element;
  disabled?: boolean;
}

interface TabsProps {
  className?: string;
  items: TabItem[];
  onChange?(item: TabItem): any;
  currentTab?: TabItem;
  setCurrentTab?: Dispatch<SetStateAction<TabItem>>;
  itemExtraClassName?: string;
}

export default function Tabs({
  className = "",
  items,
  onChange,
  currentTab,
  setCurrentTab,
  itemExtraClassName,
}: TabsProps) {
  let [curTab, setCurTab] = useState(items[0]);

  // if curTab and setCurTab are given, use them to manage the state
  // if only one of them is given, throw error
  // if none of them is given, use state to manage them
  if (currentTab !== undefined && setCurrentTab !== undefined) {
    curTab = currentTab;
    setCurTab = setCurrentTab;
  } else if (currentTab && !setCurrentTab) {
    throw Error("setCurTab is required is curTab is set");
  } else if (!currentTab && setCurrentTab) {
    throw Error("curTab is required is setCurTab is set");
  }

  const handleClick = (tab: TabItem) => {
    if (curTab === tab) return;
    !tab.disabled && setCurTab!(tab);
    onChange && onChange(tab);
  };

  return (
    <div className={className}>
      <div className="border-b border-gray-200 mb-4">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {items.map((tab) => (
            <div
              key={tab.key}
              onClick={() => handleClick(tab)}
              className={classNames(
                curTab!.key === tab.key
                  ? "border-blue-500 text-blue-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                tab.disabled
                  ? "opacity-50 cursor-not-allowed"
                  : "cursor-pointer",
                "flex h-fit  whitespace-nowrap py-3 px-1 border-b-2 font-medium transition-colors duration-300",
                itemExtraClassName
              )}
            >
              {tab.label}
            </div>
          ))}
        </nav>
      </div>
      {!!curTab!.children && curTab!.children}
    </div>
  );
}
