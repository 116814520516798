import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { HiCalendar, HiMiniXCircle } from "react-icons/hi2";
import type { DateRange } from "react-day-picker";

interface DateInputBase {
  onClick: Function;
  onClear?: Function;
  className?: string;
  picker?: "day" | "month";
}

interface DateInputRangeProps extends DateInputBase {
  mode: "range";
  selected: DateRange;
}

interface DateInputSingleProps extends DateInputBase {
  mode: "single";
  selected: Date;
}

export default function DateInput({
  mode,
  picker = "day",
  selected,
  onClick,
  onClear,
  className = "",
}: DateInputRangeProps | DateInputSingleProps) {
  const { t } = useTranslation();
  const [hasValue, setHasValue] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleClear = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    onClear!();
  };

  useEffect(() => {
    setHasValue(!!(mode === "range" ? selected.from && selected.to : selected));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div
      className={`inline-flex items-center rounded border border-gray-200 cursor-pointer hover:border-blue-500 p-1 pr-3 text-xs text-gray-500 transition-colors duration-300 ${className}`}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onClick={() => onClick()}
    >
      {mode === "range" ? (
        <>
          <span
            className={`w-24 inline-block py-1 px-2 rounded transition-colors duration-300 hover:bg-blue-50 mr-1 ${
              selected.from ? " text-black bg-blue-50" : ""
            }`}
          >
            {selected.from
              ? format(
                  selected.from,
                  picker === "day" ? "yyyy-MM-dd" : "yyyy-MM"
                )
              : t("component.common.date.from")}
          </span>
          -
          <span
            className={`w-24 inline-block py-1 px-2 rounded transition-colors duration-300 hover:bg-blue-50 mx-1 ${
              selected.to ? " text-black bg-blue-50" : ""
            }`}
          >
            {selected.to
              ? format(selected.to, picker === "day" ? "yyyy-MM-dd" : "yyyy-MM")
              : t("component.common.date.to")}
          </span>
        </>
      ) : (
        <span
          className={`w-24 inline-block py-1 px-2 rounded transition-colors duration-300 hover:bg-blue-50 mr-1 ${
            selected ? " text-black bg-blue-50" : ""
          }`}
        >
          {selected
            ? format(selected, picker === "day" ? "yyyy-MM-dd" : "yyyy-MM")
            : t("component.common.date.desc")}
        </span>
      )}
      {hasValue && isHover && onClear ? (
        <HiMiniXCircle
          className="inline-block text-base hover:text-blue-500 transition-colors duration-300"
          onClick={handleClear}
        />
      ) : (
        <HiCalendar className="inline-block text-base hover:text-blue-500 transition-colors duration-300" />
      )}
    </div>
  );
}
