import SidebarLayout from "./SidebarLayout";

interface LayoutProps {
  children: React.ReactNode;
  sidebar: React.ReactNode;
}

export default function Layout({ children, sidebar }: LayoutProps) {
  return (
    <div className="h-screen overscroll-contain pt-[100px]">
      <SidebarLayout sidebar={sidebar}>{children}</SidebarLayout>
    </div>
  );
}
