import { useEffect, useRef, useState } from "react";
import ProgressBar from "../ProgressBar";
import DialogContainer from "../DialogContainer";
import { getSizeUnit, sendFailureNotification } from "@utils/index";
import Table from "../Table";
import Spin from "../Spin";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import AlertDialog from "../AlertDialog";
import { UploadingFileItem } from "@src/utils/api/upload/upload";
import {
  HiOutlineXMark,
  HiCheckCircle,
  HiMinusCircle,
  HiCloudArrowUp,
} from "react-icons/hi2";
import type { FilterItem } from "@components/common/FilterDropdown";
import { DEFAULT_CONSOLE_APP } from "@src/constant";
import { useAppDispatch } from "@src/store";

const getTimeUnit = (time: number) => {
  let unit;
  if (time < 60) {
    unit = "s";
  } else if (time < 3600) {
    time /= 60;
    unit = "min";
  } else {
    time /= 3600;
    unit = "h";
  }
  return `${time.toFixed()} ${unit}`;
};

interface UploadProcessProps {
  isUploading: boolean;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  uploadingFilesList: UploadingFileItem[];
  progressEvent?: ProgressEvent; // listen the progressEvent to update upload progress
  onReupload?: (index: number) => Promise<void>; // reupload when upload failed
}

export default function UploadProcess({
  isUploading,
  setIsUploading,
  uploadingFilesList,
  progressEvent,
  onReupload,
}: UploadProcessProps) {
  const progressEventRef = useRef(progressEvent);
  progressEventRef.current = progressEvent;

  const isUploadingRef = useRef(isUploading);
  isUploadingRef.current = isUploading;

  const uploadingFilesListRef = useRef(uploadingFilesList);
  uploadingFilesListRef.current = uploadingFilesList;
  const [renderUploadingFilesList, setRenderUploadingFilesList] = useState<
    UploadingFileItem[] | null
  >(null);

  const [defaultFilteredValue, setDefaultFilteredValue] = useState<
    FilterItem["value"][]
  >([]);

  const [loadedSize, setLoadedSize] = useState(0);
  const loadedSizeRef = useRef(loadedSize);
  loadedSizeRef.current = loadedSize;

  const [totalSize, setTotalSize] = useState(0);
  const totalSizeRef = useRef(totalSize);
  totalSizeRef.current = totalSize;

  const [loadedFiles, setLoadedFiles] = useState(0);

  const [lastLoaded, setLastLoaded] = useState(0);
  const lastLoadedRef = useRef(lastLoaded);
  lastLoadedRef.current = lastLoaded;

  const [lastTimeStamp, setLastTimeStamp] = useState(0);
  const lastTimeStampRef = useRef(lastTimeStamp);
  lastTimeStampRef.current = lastTimeStamp;

  const [uploadSpeed, setUploadSpeed] = useState(0);
  const [remainTime, setRemainTime] = useState(0);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [openAbortAlert, setOpenAbortAlert] = useState(false);
  const [hasAbort, setHasAbort] = useState(false);
  const [hasFileUploadedFailed, setHasFileUploadedFailed] = useState(false);

  const [speedTimer, setSpeedTimer] = useState<NodeJS.Timeout>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const renderUploadProcess = (hasAbort = false) => {
    if (!uploadingFilesList) return;
    renderUploadingFilesList === null &&
      uploadingFilesList.length &&
      setRenderUploadingFilesList(uploadingFilesList);
    setHasFileUploadedFailed(false);
    let loaded = 0;
    let total = 0;
    let loadedSum = 0;
    let errSum = 0;
    uploadingFilesList.forEach((item) => {
      total += item.totalSize;
      if (item.isError || hasAbort) {
        return ++errSum;
      } else if (item.uploaded) {
        ++loadedSum;
      }
      loaded += Math.min(item.loadedSize, item.totalSize);
    });
    setLoadedSize(loaded);
    setTotalSize(total);
    setLoadedFiles(loadedSum);
    if (errSum > 0) setHasFileUploadedFailed(true);
    if (loadedSum + errSum === uploadingFilesList.length) setIsUploading(false);
  };

  const refreshUploadProcess = () => {
    setUploadSpeed(0);
    setRemainTime(0);
    setLastLoaded(0);
    setLastTimeStamp(0);
    clearTimeout(speedTimer);
  };

  const computeSpeed = () => {
    setSpeedTimer(
      setInterval(() => {
        const curLoaded = loadedSizeRef.current;
        const curTimeStamp = progressEventRef.current
          ? progressEventRef.current.timeStamp
          : 0;

        const preLoaded = lastLoadedRef.current;
        const preTimeStamp = lastTimeStampRef.current;
        const speed =
          curLoaded > preLoaded
            ? ((curLoaded - preLoaded) / (curTimeStamp - preTimeStamp)) * 1000
            : 0;
        setUploadSpeed(speed);
        setRemainTime(
          speed ? (totalSizeRef.current - loadedSizeRef.current) / speed : 0
        );
        setLastLoaded(curLoaded);
        setLastTimeStamp(curTimeStamp);
      }, 2000)
    );
  };

  const handleAbortUpload = () => {
    uploadingFilesList.forEach((uploadingFile) => {
      const xhr = uploadingFile.xhr;
      if (xhr) {
        uploadingFile.xhr!.abort();
        delete uploadingFile.xhr;
      }
    });
    renderUploadProcess();
    setIsUploading(false);
    setHasAbort(true);
    setHasFileUploadedFailed(true);
  };

  useEffect(() => {
    renderUploadProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressEvent, uploadingFilesList]);

  useEffect(() => {
    if (isUploading) {
      setRenderUploadingFilesList(null);
      setHasAbort(false);
      computeSpeed();
    } else refreshUploadProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploading]);

  const unloadListener = (e: Event) => {
    if (!isUploadingRef.current) return;
    e.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", unloadListener);
    return () => {
      window.removeEventListener("beforeunload", unloadListener);
    };
  }, []);

  const handleReupload = async (index: number) => {
    if (!onReupload) {
      sendFailureNotification(
        t("component.common.explorer.notification.reuploadFilesNotGiven"),
        dispatch
      );
      return;
    }
    onReupload(index);
  };

  return uploadingFilesList.length ? (
    <>
      <div
        data-testid="upload-process"
        className="p-4 rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5"
      >
        {isUploading ? (
          <>
            <div className="flex items-center text-sm justify-between">
              <span className="flex items-center">
                <Spin className="mr-1" size={20} />
                <span>{`${t(
                  "component.common.uploadProgress.status.uploading"
                )},`}</span>
                <span className="ml-1 font-medium">
                  {t("component.common.uploadProgress.tips.doNotExit")}
                </span>
                <a
                  target="_blank"
                  className="hover:text-blue-600 text-blue-500 font-medium ml-1"
                  rel="noreferrer"
                  href={`/console/${DEFAULT_CONSOLE_APP}`}
                >
                  {t("component.common.uploadProgress.actions.newPage")}
                </a>
              </span>
              <HiOutlineXMark
                className="w-5 h-5 cursor-pointer"
                onClick={() => setOpenAbortAlert(true)}
              />
              <AlertDialog
                open={openAbortAlert}
                setOpen={setOpenAbortAlert}
                title={t("component.common.uploadProgress.actions.abortUpload")}
                description={
                  t(
                    "component.common.uploadProgress.actions.abortUploadAlert",
                    {
                      unloadedFiles: uploadingFilesList.length - loadedFiles,
                    }
                  ) as string
                }
                buttonText={t("component.common.uploadProgress.actions.abort")}
                onConfirm={handleAbortUpload}
              />
            </div>
            <div className="text-sm">
              {`${t(
                "component.common.uploadProgress.labels.transSpeed"
              )}${getSizeUnit(uploadSpeed)}/s`}
            </div>
            <div className="text-sm">
              {`${t(
                "component.common.uploadProgress.labels.remainTime"
              )}${getTimeUnit(remainTime)}`}
            </div>
          </>
        ) : (
          <div className="flex items-center text-sm">
            {hasFileUploadedFailed ? (
              <>
                <HiMinusCircle className="mr-1 h-5 w-5 text-red-400" />
                {t("component.common.uploadProgress.tips.someFailedToUpload")}
              </>
            ) : (
              <>
                <HiCheckCircle className="mr-1 h-5 w-5 text-green-400" />
                {t("component.common.uploadProgress.status.uploaded")}
              </>
            )}
          </div>
        )}
        <div className="text-sm">
          {`${t("component.common.uploadProgress.labels.uploadProgress")}: `}
          <button
            onClick={() => setDetailDialogOpen(true)}
            className="hover:text-blue-600 text-blue-500 font-medium"
          >
            {t("common.header.detail")}
          </button>
          {`, ${t("component.common.uploadProgress.tips.uploadPercentage", {
            loadedFiles,
            totalFiles: uploadingFilesList.length,
          })}, ${getSizeUnit(loadedSize)} / ${getSizeUnit(totalSize)}`}
        </div>
        <div className="mt-1">
          <ProgressBar
            progress={
              totalSize
                ? Number(((loadedSize / totalSize) * 100).toFixed(1))
                : !isUploading && !hasFileUploadedFailed
                ? 100
                : 0
            }
          />
        </div>
      </div>
      <DialogContainer open={detailDialogOpen} setOpen={setDetailDialogOpen}>
        <Table
          className="min-h-[336px]"
          dataSource={renderUploadingFilesList || []}
          rowKey="name"
          pagination
          columns={[
            {
              title: t("common.header.name")!,
              dataIndex: "name",
              width: 320,
            },
            {
              title: t("common.header.size")!,
              width: 100,
              render: ({ totalSize }: UploadingFileItem) =>
                getSizeUnit(totalSize),
            },
            {
              title: t("common.header.status")!,
              width: 180,
              filters: [
                {
                  text: t(
                    "component.common.uploadProgress.status.uploadFailed"
                  )!,
                  value: "uploadFailed",
                },
                {
                  text: t("component.common.uploadProgress.status.uploading")!,
                  value: "uploading",
                },
                {
                  text: t("component.common.uploadProgress.status.uploaded")!,
                  value: "uploaded",
                },
              ],
              render: ({
                loadedSize,
                totalSize,
                uploaded,
                isError = false,
                index,
              }: UploadingFileItem) => (
                <div
                  className="flex items-center text-sm w-48"
                  key={`status-${index}`}
                >
                  {/* if loadedSize is equal to totalSize, means the file has been completely uploaded */}
                  {uploaded && !isError ? (
                    <>
                      <HiCheckCircle className="mr-1 h-5 w-5 text-green-400" />
                      {t("component.common.uploadProgress.status.uploaded")}
                    </>
                  ) : hasAbort || isError ? (
                    <>
                      <HiMinusCircle className="mr-1 h-5 w-5 text-red-400" />
                      {t("component.common.uploadProgress.status.uploadFailed")}
                      <Button
                        className="ml-1"
                        type="link"
                        onClick={() => {
                          handleReupload(index);
                        }}
                      >
                        {t("common.action.reupload")}
                      </Button>
                    </>
                  ) : (
                    <>
                      <HiCloudArrowUp className="mr-1 h-5 w-5" />
                      {`${t(
                        "component.common.uploadProgress.status.uploading"
                      )} (${Math.min(
                        (loadedSize / totalSize) * 100,
                        100
                      ).toFixed(1)}%)`}
                    </>
                  )}
                </div>
              ),
              filterMultiple: true,
              defaultFilteredValue,
              onFilter: (values: FilterItem["value"][]) => {
                setDefaultFilteredValue(values);
                setRenderUploadingFilesList(
                  values.length
                    ? uploadingFilesList.filter(
                        ({ uploaded, isError }: UploadingFileItem) =>
                          (values.includes("uploadFailed") &&
                            !uploaded &&
                            (isError || hasAbort)) ||
                          (values.includes("uploading") &&
                            isUploading &&
                            !uploaded &&
                            !isError &&
                            !hasAbort) ||
                          (values.includes("uploaded") && uploaded)
                      )
                    : uploadingFilesList
                );
              },
            },
          ]}
        ></Table>
      </DialogContainer>
    </>
  ) : (
    <></>
  );
}
