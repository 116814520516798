import SidePanel, { SidePanelItemProps } from "./SidePanel";

interface SidePanelLayoutProps {
  panelGroups: SidePanelItemProps[];
  children: React.ReactNode;
}

export default function SidePanelLayout({
  panelGroups,
  children,
}: SidePanelLayoutProps) {
  return (
    <div className="flex flex-1 w-full">
      <div className="w-2/3 sm:w-3/4 md:1/5 lg:w-5/6">{children}</div>
      <div className="min-h-full ml-4 w-1/3 sm:w-1/4 md:1/5 lg:w-1/6">
        <SidePanel groups={panelGroups} />
      </div>
    </div>
  );
}
