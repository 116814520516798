import { ProtectedFetcher } from "./http";

export const SERVER = process.env.REACT_APP_PUBLIC_SERVER || "";

// we need to init the global fetcher from the beginning
// and do not set use a new fetcher to replace it
// this can cause some API call error when the fetcher is replaced
// keep this object singleton

export let globalLanguage =
  (typeof window !== "undefined" && window.localStorage.getItem("language")) ||
  undefined;

export let globalFetcher: ProtectedFetcher = new ProtectedFetcher(
  `${SERVER}/api/user/refresh`
);

export function getLanguage() {
  const lang = globalLanguage;

  // if language is not set, check the browser language
  // if the browser language is en/zh, set it as default
  // otherwise, set the language to en
  if (!lang) {
    const browserLng = navigator.language.substring(0, 2);
    const language =
      browserLng === "en" || browserLng === "zh" ? browserLng : "zh";
    setLanguage(language);
    return language;
  } else return lang;
}

export function setLanguage(language: string) {
  globalLanguage = language;
  window && window.localStorage.setItem("language", language);
}

const exports = {
  SERVER,
  globalFetcher,
  globalLanguage,
  getLanguage,
  setLanguage,
};

export default exports;
