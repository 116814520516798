import { classNames } from "@src/utils";
import { useTranslation } from "react-i18next";
import Checkbox from "./Checkbox";

interface InputProps extends Record<string, any> {
  id?: string;
  label?: JSX.Element | string;
  name?: string;
  type?: "checkbox" | "text" | "float" | "number" | "password" | "email";
  className?: string;
  defaultValue?: any;
  trailingAddOn?: string;
  isError?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Input({
  id,
  label,
  name,
  type = "text",
  className,
  defaultValue,
  trailingAddOn,
  isError = false,
  ...other
}: InputProps) {
  const { t } = useTranslation();

  return (
    <>
      {!!label && (
        <label
          htmlFor={id}
          className={`block text-sm font-medium text-gray-700 mb-1${
            other.disabled ? " opacity-50" : ""
          }`}
        >
          {label}
        </label>
      )}
      <div className="flex items-center">
        {type !== "checkbox" ? (
          <input
            id={id}
            // support float type
            type={type === "float" ? "number" : type}
            {...(type === "number" || type === "float"
              ? {
                  onWheel: (e) => e.currentTarget.blur(),
                  step: type === "number" ? "1" : "any",
                }
              : {})}
            name={name}
            defaultValue={defaultValue}
            {...other}
            {...(other.required
              ? {
                  onInvalid: (e) =>
                    (e.target as HTMLSelectElement).setCustomValidity(
                      t("common.tip.fieldIsCompulsory")
                    ),
                  onInput: (e) =>
                    (e.target as HTMLSelectElement).setCustomValidity(""),
                }
              : {})}
            className={classNames(
              `flex-1 shadow-sm block rounded-md border-gray-300 placeholder:text-gray-400 pl-2 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed ${
                isError
                  ? "!border-red-500 !ring-red-500"
                  : "focus:border-blue-500 focus:ring-blue-500"
              }`,
              className
            )}
          />
        ) : (
          <Checkbox
            id={id}
            name={name || ""}
            label={label}
            defaultChecked={defaultValue}
            className={className}
            {...other}
          />
        )}

        {trailingAddOn && (
          <div className="ml-2 pointer-events-none flex items-center">
            <span className="text-gray-500 sm:text-sm">{trailingAddOn}</span>
          </div>
        )}
      </div>
    </>
  );
}
