import { ChangeEvent, useEffect, useRef, useState } from "react";
import Empty from "./Empty";
import Spin from "./Spin";
import type { PaginationProps } from "./Pagination";
import Pagination from "./Pagination";
import type { FilterDropdownProps, FilterItem } from "./FilterDropdown";
import FilterDropdown from "./FilterDropdown";
import { getObjectAttribute, renderInfo } from "@utils/index";

export interface ColumnItem<T> {
  title: JSX.Element | string;
  dataIndex?: string; // required between {dataIndex} and {render}
  render?: (record: T, rowIndex: number) => JSX.Element | string;
  width?: string | number; // column width
  className?: string; // column class
  filters?: FilterItem[];
  defaultFilteredValue?: FilterItem["value"][];
  onFilter?: FilterDropdownProps["onFilter"];
  filterMultiple?: boolean;
  fixed?: "left" | "right";
}

interface TableProps<T> {
  className?: string; // if table has filter item, it's better to set min-h-[336px] in case that filter dropdown overflows
  columns: ColumnItem<T>[];
  dataSource: T[];
  rowKey?: string;
  rowSelection?: {
    onChange: (selectedRows: T[]) => any;
    isSingle?: boolean;
    defaultSelected?: T[];
    checkDisabled?: (val: T) => boolean;
  };
  loading?: boolean;
  pagination?: boolean | PaginationProps;
  textOnEmpty?: string;
  flexible?: boolean; // if it's true, this component height will be 100% of the container el's height
}

const defaultPageSize = 10;

export default function Table<T = Record<string, any>>({
  className = "",
  columns,
  dataSource,
  rowKey,
  rowSelection,
  loading = false,
  pagination,
  textOnEmpty,
  flexible = false,
}: TableProps<T>) {
  // *** pagination context ***
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(1);
  const [renderDataSource, setRenderDataSource] = useState<T[]>([]);

  const defaultHandlePageChange = (currentPage: number, pageSize: number) => {
    setCurrentPage(currentPage);
    const startIndex = (currentPage - 1) * defaultPageSize;
    setRenderDataSource(
      dataSource.slice(startIndex, startIndex + defaultPageSize)
    );
  };

  useEffect(() => {
    if (pagination === true) {
      setRenderDataSource(dataSource.slice(0, defaultPageSize));
      setCurrentPage(1);
      setTotalEntries(dataSource.length);
    } else {
      setRenderDataSource(dataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  // *** row select context ***
  const checkbox = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState<T[]>(
    rowSelection?.defaultSelected || []
  );
  const [checked, setChecked] = useState(false);

  // table header checkbox render
  useEffect(() => {
    if (!checkbox.current || !rowSelection) return;
    if (!selected.length) {
      checkbox.current.indeterminate = false;
      setChecked(false);
    } else if (selected.length < dataSource.length)
      checkbox.current.indeterminate = true;
    else if (selected.length === dataSource.length) {
      checkbox.current.indeterminate = false;
      setChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    setChecked(false);
    if (checkbox.current) {
      checkbox.current.indeterminate = false;
      setSelected([]);
    }
  }, [dataSource]);

  const handleAllItemsToggle = () => {
    if (!dataSource.length) return;
    const curSelected = !checked && selected.length === 0 ? dataSource : [];
    setSelected(curSelected);
    rowSelection!.onChange(curSelected);
  };

  const handleItemToggle = (e: ChangeEvent<HTMLInputElement>, data: T) => {
    const curSelected = rowSelection?.isSingle
      ? [data]
      : e.target.checked
      ? [...selected, data]
      : selected.filter((p) => p !== data);

    setSelected(curSelected);
    rowSelection!.onChange(curSelected);
  };

  return (
    <div className={flexible ? "flex flex-col h-full" : ""}>
      <div className={`relative w-full${flexible ? " h-0 flex-1" : ""}`}>
        <div
          className={`w-full max-h-full overflow-auto ${className}${
            loading ? " !overflow-hidden" : ""
          }`}
        >
          <table className="w-full divide-y divide-gray-100">
            <thead className="shadow-sm border-b bg-gray-50">
              <tr className="z-40 relative">
                {!!rowSelection?.onChange && (
                  <th scope="col" className="relative px-5">
                    {!rowSelection?.isSingle && (
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded text-blue-600 focus:ring-blue-500 sm:left-6 cursor-pointer"
                        ref={checkbox}
                        checked={checked}
                        onChange={handleAllItemsToggle}
                      />
                    )}
                  </th>
                )}
                {columns.map((col, index) => (
                  <th
                    key={index}
                    scope="col"
                    className={`first:pl-6 first:pr-3 last:pl-3 last:pr-6 px-3 py-3.5 text-left text-sm font-medium text-gray-900${
                      col.fixed
                        ? col.fixed === "left"
                          ? " bg-gray-50 sticky left-0 after:shadow-[2px_0_2px_#f3f4f6] after:absolute after:right-0 after:top-0 after:w-1 after:h-full z-10"
                          : " bg-gray-50 sticky right-0 after:shadow-[-2px_0_2px_#f3f4f6] after:absolute after:left-0 after:top-0 after:w-1 after:h-full z-10"
                        : ""
                    }`}
                  >
                    <div
                      style={{
                        minWidth: col.width ? `${col.width}px` : "fit-content",
                      }}
                      className="whitespace-nowrap"
                    >
                      {col.title}
                      {!!col.filters && (
                        <FilterDropdown
                          defaultFilteredValue={col.defaultFilteredValue}
                          className="float-right mt-[2px]"
                          optionsClassName="w-40 right-0"
                          filterMultiple={!!col.filterMultiple}
                          filters={col.filters}
                          onFilter={col.onFilter!}
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white">
              {!!dataSource?.length &&
                renderDataSource.map((data, rowIndex) => {
                  const hasSelect = !!selected.find(
                    (item: any) => item[rowKey!] === (data as any)[rowKey!]
                  );

                  const isDisabled = rowSelection?.checkDisabled
                    ? rowSelection.checkDisabled(data)
                    : false;

                  return (
                    // <FadeIn>
                    <tr
                      key={
                        rowKey
                          ? getObjectAttribute(
                              data as Record<string, any>,
                              rowKey
                            )
                          : rowIndex
                      }
                      className={`border-b border-gray-200 relative transition-colors duration-300 animate-fade animate-duration-500 ${
                        hasSelect ? " bg-blue-50" : ""
                      }`}
                      style={{ zIndex: renderDataSource.length + 1 - rowIndex }}
                    >
                      {!!rowSelection?.onChange && (
                        <td className="relative px-5">
                          {hasSelect && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                          )}
                          <input
                            type="checkbox"
                            disabled={isDisabled}
                            className={`absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6 ${
                              isDisabled
                                ? "cursor-not-allowed bg-gray-200"
                                : "cursor-pointer"
                            }`}
                            checked={hasSelect}
                            onChange={(e) => handleItemToggle(e, data)}
                          />
                        </td>
                      )}
                      {columns.map((col, colIndex) => (
                        <td
                          key={colIndex}
                          className={`first:pl-6 first:pr-3 last:pl-3 last:pr-6 px-3 py-4${
                            col.fixed
                              ? col.fixed === "left"
                                ? " bg-white sticky left-0 after:shadow-[2px_0_2px_#f3f4f6] after:absolute after:right-0 after:top-0 after:w-1 z-[1] after:h-full"
                                : " bg-white sticky right-0 after:shadow-[-2px_0_2px_#f3f4f6] after:absolute after:left-0 after:top-0 after:w-1 z-[1] after:h-full"
                              : ""
                          }`}
                          style={{
                            zIndex: renderDataSource.length + 1 - rowIndex,
                          }}
                        >
                          <div
                            className={`text-sm break-words text-gray-500 ${
                              col.className || ""
                            }`}
                            style={{
                              width: col.width
                                ? `${col.width}px`
                                : "fit-content",
                            }}
                          >
                            {renderInfo(
                              col.render
                                ? col.render(
                                    data,
                                    pagination
                                      ? ((pagination === true
                                          ? currentPage
                                          : pagination.current) -
                                          1) *
                                          defaultPageSize +
                                          rowIndex
                                      : rowIndex
                                  )
                                : (data as Record<string, any>)[col.dataIndex!]
                            )}
                          </div>
                        </td>
                      ))}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {!loading && !dataSource?.length && (
            <div className="h-72 flex justify-center items-center">
              <Empty className="my-24 w-24 m-auto" text={textOnEmpty} />
            </div>
          )}
        </div>

        {loading && (
          <>
            <Spin
              className="absolute z-50 top-1/2 left-1/2 -translate-x-1/2"
              size={40}
            />
            <div className="absolute bottom-0 h-full w-full z-40 opacity-50 bg-white" />
            {!dataSource?.length && <div className="h-72" />}
          </>
        )}
      </div>

      {/* pagination */}
      {!!dataSource?.length && !!pagination && (
        <Pagination
          current={pagination === true ? currentPage : pagination.current}
          defaultPageSize={
            pagination === true ? defaultPageSize : pagination.defaultPageSize
          }
          total={pagination === true ? totalEntries : pagination.total}
          onChange={
            pagination === true ? defaultHandlePageChange : pagination.onChange
          }
        />
      )}
    </div>
  );
}
